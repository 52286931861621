/**
 * multi-family unit custom GTAG events
 */
export const GTAG_EVENT_MF_AGENT_TOUR_FORM = 'MFagentTourForm'
export const GTAG_EVENT_MF_SELF_TOUR_FORM = 'MFselfTourForm'
export const GTAG_EVENT_MF_CONTACT_US_SUBMISSION = 'MFcontactUsSubmission'
export const GTAG_EVENT_SFRC_CONTACT_US_SUBMISSION = 'SFRCcontactUsSubmission'
export const GTAG_EVENT_MF_CREATE_BROCHURE = 'MFcreateBrochure'
export const GTAG_EVENT_MF_VIRTUAL_TOUR = 'MFvirtualTourClick'
/**
 * single-family unit custom GTAG events
 */
export const GTAG_EVENT_SF_AGENT_TOUR_FORM = 'SFagentTourForm'
export const GTAG_EVENT_SF_REQUEST_SHOWING = 'SFrequestShowing'
export const GTAG_EVENT_SF_SELF_TOUR_FORM = 'SFselfTourForm'
export const GTAG_EVENT_SF_OPEN_HOUSE_FORM = 'SFopenHouseForm'
export const GTAG_EVENT_SF_RECEIVE_UPDATES = 'SFreceiveUpdates'
export const GTAG_EVENT_SF_CREATE_BROCHURE = 'SFcreateBrochure'

/**
 * @deprecated use GTAG_EVENT_SF_VIRTUAL_TOUR
 * @type {string}
 */
export const GTAG_EVENT_SF_INDIVIDUAL_HOME_3060_TOUR = 'indivHome360Tour'
export const GTAG_EVENT_SF_VIRTUAL_TOUR = 'SFvirtualTourClick'
export const GTAG_EVENT_INTERNAL_SEARCH = 'internalSearch'
export const GTAG_PARAM_INTERNAL_SEARCH_TERM = 'internalSearchTerm'

export const GTAG_EVENT_SEARCH_CLICK = 'searchWidgetLinkClick'
export const GTAG_PARAM_SEARCH_CLICK = 'searchWidgetLink'

/**
 * this is the google tag parameter for KMI form completions
 * @type {string}
 */
export const GTAG_PARAM_KMI_COMPLETE = 'getNotifiedFormCompletion'

export const pushGTM = (eventName, params = {}) => {
  const event = {
    event: eventName
  }
  Object.assign(event, params)
  console.log('window.dataLayer.push', eventName, params, event)
  if ('dataLayer' in window) {
    window.dataLayer.push(event)
  } else if ('dataLayer' in document) {
    document.dataLayer.push(event)
  }
}
