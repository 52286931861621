<template>
  <div id="app">
    <template v-if="!isEmpty(portals.SearchWidget)">
      <template v-for="searchWidget of portals.SearchWidget">
        <MountingPortal v-if="portalExistsInPage(searchWidget.portal_id)" :mountTo="'#' + searchWidget.portal_id" name="source" :key="searchWidget.portal_id">
          <search-widget v-bind="searchWidget.portal_data"/>
        </MountingPortal>
      </template>
    </template>

    <!-- start mounting of the wordpress blocks, they append so they do not remove wrapper sections -->
    <template v-if="!isEmpty(portals.NeighborhoodMap)">
      <template v-for="neighborhoodMap of portals.NeighborhoodMap">
        <MountingPortal :mountTo="'#' + neighborhoodMap.portal_id" name="source" :key="neighborhoodMap.portal_id">
          <neighborhood-map v-bind="neighborhoodMap.portal_data">
              <template v-for="(slot, slotName) in neighborhoodMap.slots" v-slot:[slotName] v-start-with-html="slot">
                <div class="entryContent" v-html="slot" :key="slotName" />
              </template>

          </neighborhood-map>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.NearbyMap)">
      <template v-for="nearbyMap of portals.NearbyMap">
        <MountingPortal :mountTo="'#' + nearbyMap.portal_id" name="source" :key="nearbyMap.portal_id">
          <nearby-map-portal v-bind="nearbyMap.portal_data">
            <template v-for="(slot, slotName) in nearbyMap.slots" v-slot:[slotName] v-start-with-html="slot">
              <div class="entryContent" v-html="slot" :key="slotName" />
            </template>
          </nearby-map-portal>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.HomesBlock)">
      <template v-for="homesBlock of portals.HomesBlock">
        <MountingPortal :mountTo="'#' + homesBlock.portal_id" name="source" :key="homesBlock.portal_id">
          <homes-block v-bind="homesBlock.portal_data"/>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.HomesCompare)">
      <template v-for="homesCompare of portals.HomesCompare">
        <MountingPortal :mountTo="'#' + homesCompare.portal_id" name="source" :key="homesCompare.portal_id">
          <homes-compare v-bind="homesCompare.portal_data"/>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.CommunityBlock)">
      <template v-for="communityBlock of portals.CommunityBlock">
        <MountingPortal :mountTo="'#' + communityBlock.portal_id" name="source" :key="communityBlock.portal_id">
          <community-block v-bind="communityBlock.portal_data"/>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.ApartmentsBlock)">
      <template v-for="apartmentsBlock of portals.ApartmentsBlock">
        <MountingPortal :mountTo="'#' + apartmentsBlock.portal_id" name="source" :key="apartmentsBlock.portal_id">
          <apartments-block v-bind="apartmentsBlock.portal_data"/>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.SelectedCollectionGrid)">
      <template v-for="selectedCollectionGrid of portals.SelectedCollectionGrid">
        <MountingPortal :mountTo="'#' + selectedCollectionGrid.portal_id" name="source" :key="selectedCollectionGrid.portal_id">
          <selected-collection-grid v-bind="selectedCollectionGrid.portal_data"/>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.KeepMeInformedDialog)">
      <template v-for="kmi of portals.KeepMeInformedDialog">
        <MountingPortal :mountTo="'#' + kmi.portal_id" name="source" :key="kmi.portal_id">
          <keep-me-informed-dialog v-bind="kmi.portal_data" />
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.KeepMeInformed)">
      <template v-for="keepMeInformed of portals.KeepMeInformed">
        <MountingPortal :mountTo="'#' + keepMeInformed.portal_id" name="source" :key="keepMeInformed.portal_id">
          <keep-me-informed v-bind="keepMeInformed.portal_data"/>
        </MountingPortal>
      </template>
    </template>
  </div>
</template>

<script>

import { initialSettings, isEmpty } from '@/utilities/helpers'
import { mapActions } from 'vuex'
import SearchWidget from '@/components/search_widget/SearchWidget'
export default {
  name: 'App',
  components: {
    SearchWidget,
    NeighborhoodMap: () => import(/* webpackChunkName: "NeighborhoodMap" */ '@/components/neighborhood_map/NeighborhoodMap'),
    NearbyMapPortal: () => import(/* webpackChunkName: "NearbyMapPortal" */ '@/components/nearby_map/NearbyMapPortal'),
    HomesBlock: () => import(/* webpackChunkName: "HomesBlock" */ '@/components/homes_block/HomesBlock'),
    HomesCompare: () => import(/* webpackChunkName: "HomesCompare" */ '@/components/homes_block/HomesCompare'),
    CommunityBlock: () => import(/* webpackChunkName: "CommunityBlock" */ '@/components/community_block/CommunityBlock'),
    ApartmentsBlock: () => import(/* webpackChunkName: "ApartmentsBlock" */ '@/components/apartments_block/ApartmentsBlock'),
    SelectedCollectionGrid: () => import(/* webpackChunkName: "SelectedCollectionGrid" */ '@/components/selected_collection_grid/SelectedCollectionGrid'),
    KeepMeInformed: () => import(/* webpackChunkName: "KeepMeInformed" */ '@/components/keep_me_informed/KeepMeInformed'),
    KeepMeInformedDialog: () => import(/* webpackChunkName: "KeepMeInformedDialog" */ '@/components/keep_me_informed/KeepMeInformedDialog')
  },
  data () {
    return {
      is: null,
      portals: {}
    }
  },
  directives: {
    startWithHtml: {
      inserted (el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value)
      }
    }
  },
  created () {
    console.time('App.created')
    const initSetting = initialSettings()
    this.portals = JSON.parse(initSetting.portals)
    for (const portalsKey in this.portals) {
      const portalType = this.portals[portalsKey]
      console.log('portals', portalsKey, portalType)
      portalType.forEach(portal => {
        const portalData = portal.portal_data
        portal.slots = { }
        if (!isEmpty(portalData['slot-ids'])) {
          const slotIds = portalData['slot-ids']
          for (const slotName in slotIds) {
            const slotId = slotIds[slotName]
            const slot = document.getElementById(slotId)
            portal.slots[slotName] = slot.innerHTML
          }
        }
      })
    }
    this.createDialogMountPortal()
    this.initFavorites()
    this.initRecents()
    this.initRegions()
    console.timeEnd('App.created')
  },
  methods: {
    ...mapActions({
      initRegions: 'initRegions',
      initFavorites: 'initFavorites',
      initRecents: 'initRecents'
    }),
    isEmpty,
    createDialogMountPortal () {
      const portal = document.createElement('div')
      portal.id = 'dialog_mount_portal'
      const parent = document.getElementById('loadVue').parentNode
      parent.appendChild(portal)
    },
    portalExistsInPage (id) {
      const portalElement = document.getElementById(id)
      console.log('App.portalExistsInPage', id, portalElement !== null, portalElement)
      console.trace('App.portalExistsInPage')
      return portalElement !== null
    }
  }
}
</script>

<style >

</style>
