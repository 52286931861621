/**
 *
 * @type {number} the number of available units to hide Load More button
 * when it is selected in the ui, so that it is not confusing when the
 * amount of units all show by default
 */
const AVAILABILITY_BLOCK_LOAD_MORE_THRESHOLD = 6
const COMPARE_HOMES_UPDATE_EVENT = 'compare-homes-update'
const COMPARE_HOMES_PATH = '/compare/'

export {
  AVAILABILITY_BLOCK_LOAD_MORE_THRESHOLD,
  COMPARE_HOMES_UPDATE_EVENT, COMPARE_HOMES_PATH
}
