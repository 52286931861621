import {
  swapClassesFromExternalElement
} from '@/utilities/helpers'

export const mountingMixin = {
  props: {
    wrapperId: {
      type: String,
      default: '',
      required: false
    }
  },
  mounted () {
    swapClassesFromExternalElement(this.wrapperId, 'loading', 'isLoaded')
    swapClassesFromExternalElement(this.wrapperId, 'mounting', 'isMounted')
  }
}
