import Cookies from 'js-cookie'
import { isEmpty } from '@/utilities/helpers'

/**
 * use local storage but with an expiration date
 * @param {string} key
 * @param {string} value
 * @param {Integer|Number} daysUntilExpired
 * @param {Object|null} cookieOptions if cookieOptions == null then do not save cookies
 */
const setWithExpiry = (key, value, daysUntilExpired = 0, cookieOptions = null) => {
  const now = new Date()
  if (daysUntilExpired === null) {
    daysUntilExpired = 0
  }

  let ttl = daysUntilExpired * 24 * 60 * 60 * 1000
  if (ttl === 0) {
    // set to one day
    ttl = 24 * 60 * 60 * 1000
  }
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  if (cookieOptions !== null) {
    if (daysUntilExpired !== 0) {
      cookieOptions.expires = daysUntilExpired
    }
    Cookies.set(key, value, cookieOptions)
  }

  localStorage.setItem(key, JSON.stringify(item))
}

/**
 *
 * @param key
 * @param includeCookie
 * @returns {null|*}
 */
const getWithExpiry = (key, includeCookie = true) => {
  // try to get cookie first
  const value = Cookies.get(key)
  const itemStr = localStorage.getItem(key)
  if (includeCookie && !isEmpty(value)) {
    return value
  }
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

const clearExpiredLocalStorage = () => {
  var eachitem
  var eachkey

  // Loop all localStorage items that has an expiry date
  for (var i = 0; i < localStorage.length; i++) {
    eachitem = localStorage.getItem(localStorage.key(i))
    eachkey = localStorage.key(i)
    // If value includes "expiry", call GetWithExpiry to read it and delete if expired
    if (eachitem.includes('expiry')) {
      // Call function to read it and delete if expired
      dummyitem = getWithExpiry(eachkey)
    }
  }
}

export { setWithExpiry, getWithExpiry, clearExpiredLocalStorage }
