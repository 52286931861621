import Vue from 'vue'
import get from 'lodash/get'
const setTREnvironment = (options = {}) => {
  const trEnvironment = Object.assign(options)
  trEnvironment.isEUVisitor = get(window, '__INITIAL_SETTINGS__.location.is_eu', false)
  trEnvironment.lastTag = 'local'
  trEnvironment.buildTime = (new Date()).toISOString()
  if (typeof process.env.MIX_LAST_TAG !== 'undefined') {
    trEnvironment.lastTag = process.env.MIX_LAST_TAG
  }
  if (typeof process.env.MIX_BUILD_TIME !== 'undefined') {
    trEnvironment.buildTime = process.env.MIX_BUILD_TIME
  }
  console.warn(`Version: ${trEnvironment.buildTime} (${trEnvironment.lastTag})`)

  trEnvironment.sentryEnvironment = process.env.MIX_SENTRY_ENVIRONMENT
  trEnvironment.nodeEnv = process.env.NODE_ENV
  trEnvironment.isDevelop = process.env.NODE_ENV !== 'production'
  trEnvironment.isProduction = !trEnvironment.isDevelop
  Vue.prototype.$trEnvironment = trEnvironment
  console.log('setTREnvironment', trEnvironment)
}
export { setTREnvironment }
