const sentryFilter = {
  ignoreErrors: [
    // Facebook borked
    'fb_xd_fragment',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'Syntax error, unrecognized expression',
    'Expected mapDiv of type HTMLElement',
    // otBannerSdk
    'r.beacon', 'otBannerSdk'
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /cdn\.cookielaw\.org/i
  ]

}

export { sentryFilter }
