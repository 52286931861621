import { TRICON_API, getRefreshToken, setToken } from './http-utils'
import { getMarketingObject } from '@/utilities/marketing'

const STATE_FOR_FAVORITE_LOGIN = 'stateForFavoriteLogin'

const enableFavorites = () => {
  return true
}

const getFavorites = (params) => {

  /*
  const favoritesJson = localStorage.getItem('favorites')
  let favorites = {}
  if (typeof favoritesJson !== 'undefined' && favoritesJson !== null) {
    favorites = JSON.parse(favoritesJson)
  }
  */
  return TRICON_API.get('/property/favorite', { params: params })
    .then(results => {
      return results.data
    })
    .catch(e => {
      console.error('getFavorites.catch', e)
      return []
    })
}

const addFavorite = (home) => {
  const favorite = getMarketingObject()
  favorite.YardiKey = home.unit_code
  // const favoritesJson = localStorage.getItem('favorites')
  const favorites = {}
  // if (typeof favoritesJson !== 'undefined' && favoritesJson !== null) {
  //   favorites = JSON.parse(favoritesJson)
  // }
  return TRICON_API.post('/property/favorite', favorite)
    .then(result => {
      if (result.status === 200) {
        favorites[home.unit_code] = favorite
        // localStorage.setItem('favorites', JSON.stringify(favorites))
        return favorite
      } else {
        const error = new Error(result.statusText)
        error.result = result
        throw error
      }
    })
}

const removeFavorite = (home) => {
  const params = {
    yardiKey: home.unit_code
  }
  return TRICON_API.delete('/property/favorite/', { params: params })
    .then(response => {
      // const favoritesJson = localStorage.getItem('favorites')
      // const favorites = JSON.parse(favoritesJson)
      // delete favorites[home.unit_code]
      // localStorage.setItem('favorites', JSON.stringify(favorites))
      return response
    })
}

const refreshToken = () => {
  const refreshToken = getRefreshToken()
  if (refreshToken !== null) {
    return TRICON_API.post('/auth/refresh', { RefreshToken: getRefreshToken() })
      .then(response => {
        if (response.status === 200) {
          setToken(response.data.AccessToken)
        }
        return response
      })
      .catch(e => {
        console.error('refreshToken.catch', e)
      })
  } else {
    throw new Error('No refresh token')
  }
}
// login url of the form
// https://app-feature-sandbox.triconah.com/public/login/callback/https:%2F%2Fwww.google.com%2F
export {
  getFavorites, addFavorite, removeFavorite, enableFavorites, refreshToken, STATE_FOR_FAVORITE_LOGIN
}
