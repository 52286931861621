<template>
  <ul class="widgetResults open"
      @keydown.enter="clickRef"
      @keydown.tab.prevent="focusNext"
      @keydown.down.prevent="focusNext"
      @keydown.right.prevent="focusNext"
      @keydown.up.prevent="focusPrevious"
      @keydown.left.prevent="focusPrevious"
  >
    <li v-if="loading" class="loading">Searching...</li>
    <template v-else>
      <li v-if="showNoResultsText" class="noResultsText">No results found</li>
      <template v-if="false">
        <li class="defaultRegion">
          <a v-if="!isEmpty(region.path)"
             @click="clickRegion(region, $event)"
             :href="region.path"
             :ref="regionRefSlug(region)"
             tabindex="-1"
             class="regionLink"
          >
            {{ region.name }}
            <span class="closestRegion">Closest Region</span>
            <svg role="presentation">
              <use :xlink:href="iconPath('location')"></use>
            </svg>
          </a>
          <a v-else
             v-on:click="clickRegion(region, $event)"
             class="regionLink"
             :ref="regionRefSlug(region)"
             tabindex="-1">
            {{ region.name }}
            <svg role="presentation">
              <use :xlink:href="iconPath('search')"></use>
            </svg>
          </a>
        </li>
      </template>
      <li v-for="otherRegion in regionsForList"
          :key="'region_' + otherRegion.id" class="otherRegions">
        <a v-on:click="clickRegion(otherRegion, $event)"
           class="regionLink"
           :ref="regionRefSlug(otherRegion)"
           tabindex="-1">

          <span v-html="otherRegion.name"/>
          <svg role="presentation">
            <use :xlink:href="iconPath('search')"></use>
          </svg>
        </a>
      </li>
    </template>
    <li  v-if="showMapSearch && !loading">
      <a href="/find/"
           class="findLink"
           tabindex="-1"
           ref="mapSearch">
      Map Search
      <svg role="presentation">
        <use :xlink:href="iconPath('region')"></use>
      </svg>
    </a>
    </li>
  </ul>
</template>
<script>

import { slugify, plural } from '@/utilities/stringFormatters'
import { iconPath } from '@/utilities/urlHelpers'
import { isEmpty } from '@/utilities/helpers'
import { searchWidgetMixin } from '@/components/mixins/searchWidget'
import { pushGTM, GTAG_EVENT_SEARCH_CLICK, GTAG_PARAM_SEARCH_CLICK } from '@/api/gtag-utils'
export default {
  name: 'SearchWidgetEmptyResults',
  mixins: [searchWidgetMixin],
  props: {
    region: {
      required: true
    },
    regions: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    maxResultsDisplay: {
      type: Number,
      default: 5,
      required: false
    },
    showNoResultsText: {
      type: Boolean,
      required: false,
      default: true
    },
    showMapSearch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  created () {
  },
  computed: {
    regionsForList () {
      return []
      // const regions = this.regions.filter(region => {
      //   return region.active_home_count > 0
      // })
      // return regions.sort((a, b) => a.name.localeCompare(b.name))
    },
    linkRefs () {
      return [this.regionRefSlug(this.region), 'mapSearch']
    }
  },
  methods: {
    isEmpty,
    iconPath,
    plural,
    clickRegion (region, event) {
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: '/region/' + region.slug + '/' })
      this.$emit('did-click-link', {
        type: 'region',
        region: region
      }, event)
    },

    clickHome (home, event) {
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: '/home/' + home.slug + '/' })
      this.$emit('did-click-link', {
        type: 'home',
        home: home
      }, event)
    },

    clickMore (search, event) {
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: '/find?text-query=' + encodeURIComponent(search) })
      this.$emit('did-click-link', {
        type: 'search',
        text_query: search
      }, event)
    },
    slug (s) {
      return slugify(s)
    }
  }
}
</script>
