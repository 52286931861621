// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::                                                                         :::
// :::  This routine calculates the distance between two points (given the     :::
// :::  latitude/longitude of those points). It is being used to calculate     :::
// :::  the distance between two locations using GeoDataSource (TM) products  :::
// :::                                                                         :::
// :::  Definitions:                                                           :::
// :::    South latitudes are negative, east longitudes are positive           :::
// :::                                                                         :::
// :::  Passed to function:                                                    :::
// :::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
// :::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
// :::    unit = the unit you desire for results                               :::
// :::           where: 'M' is statute miles (default)                         :::
// :::                  'K' is kilometers                                      :::
// :::                  'N' is nautical miles                                  :::
// :::                                                                         :::
// :::  Worldwide cities and other features databases with latitude longitude  :::
// :::  are available at https://www.geodatasource.com                         :::
// :::                                                                         :::
// :::  For enquiries, please contact sales@geodatasource.com                  :::
// :::                                                                         :::
// :::  Official Web site: https://www.geodatasource.com                       :::
// :::                                                                         :::
// :::               GeoDataSource.com (C) All Rights Reserved 2018            :::
// :::                                                                         :::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

const distance = (lat1, lon1, lat2, lon2, unit = 'M') => {
	if (lat1 === lat2 && lon1 === lon2) {
		return 0;
	} else {
		var radlat1 = (Math.PI * lat1) / 180;
		var radlat2 = (Math.PI * lat2) / 180;
		var theta = lon1 - lon2;
		var radtheta = (Math.PI * theta) / 180;
		var dist =
			Math.sin(radlat1) * Math.sin(radlat2) +
			Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit === 'K') {
			dist = dist * 1.609344;
		}
		if (unit === 'N') {
			dist = dist * 0.8684;
		}
		return dist;
	}
};

function rad2degr(rad) {
	return (rad * 180) / Math.PI;
}

function degr2rad(degr) {
	return (degr * Math.PI) / 180;
}

/**
 * @param latLngArray array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [{lat: latInDegree, lng: lngInDegree},
 *   {lat: latInDegree, lng: lngInDegree} ...]
 *
 * @return  object {lat: latInDegree, lng: lngInDegree}.
 */
const getLatLngCenter = (latLngArray) => {
	var sumX = 0;
	var sumY = 0;
	var sumZ = 0;

	for (var i = 0; i < latLngArray.length; i++) {
		var lat = degr2rad(latLngArray[i].lat);
		var lng = degr2rad(latLngArray[i].lng);
		// sum of cartesian coordinates
		sumX += Math.cos(lat) * Math.cos(lng);
		sumY += Math.cos(lat) * Math.sin(lng);
		sumZ += Math.sin(lat);
	}

	var avgX = sumX / latLngArray.length;
	var avgY = sumY / latLngArray.length;
	var avgZ = sumZ / latLngArray.length;

	// convert average x, y, z coordinate to latitude and longtitude
	var centerLng = Math.atan2(avgY, avgX);
	var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
	var centerLat = Math.atan2(avgZ, hyp);

	return {
		lat: rad2degr(centerLat),
		lng: rad2degr(centerLng),
	};
};

const getRadius = (center, latLngArray, setDistance = true) => {
	const radius = latLngArray.reduce((accumulator, currentLatLng) => {
		const distanceToCenter = distance(
			center.lat,
			center.lng,
			currentLatLng.lat,
			currentLatLng.lng
		);
		if (setDistance) {
			currentLatLng.distance = distanceToCenter;
		}
		return Math.max(accumulator, distanceToCenter);
	}, 0);
	return radius;
};

const metersPerPixel = (lat, zoom) =>
	(156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);

const distanceForZoom = (lat, zoom, height, width) => {
	return metersPerPixel(lat, zoom) * Math.min(height, width);
};

const zoomToShowDistance = (distanceInMeters, height, width, lat, startingZoom = 1) => {
	let zoom = startingZoom;
	let distance = metersPerPixel(lat, zoom) * Math.min(height, width);
	while (distance < distanceInMeters && zoom > 20) {
		zoom = zoom + 1;
		distance = metersPerPixel(lat, zoom) * Math.min(height, width);
	}
	return zoom;
};

/**
 * this returns a JS array that is based on a default set of styles that have an overide based
 * on the passed in non-empty string of dark, terrain, or poi that enhance/ alter the return
 * value
 * @param {string} theme (default: '', dark, terrain, poi)
 * @return {{mapTypeControl: boolean, clickableIcons: boolean, scaleControl: boolean, scrollwheel: boolean, streetViewControl: boolean, fullscreenControl: boolean, styles: [{featureType: string, stylers: [{weight: string}], elementType: string},{featureType: string, stylers: [{color: string}], elementType: string},{featureType: string, stylers: [{visibility: string},{color: string}], elementType: string},{featureType: string, stylers: [{visibility: string},{color: string}], elementType: string},{featureType: string, stylers: [{color: string}], elementType: string},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null], zoomControl: boolean, zoomControlOptions: {position: number}, disableDefaultUi: boolean, rotateControl: boolean}}
 */
const defaultMapConfiguration = (theme) => {
	let options = {
		zoomControl: true,
		zoomControlOptions: {
			position: 7,
		},
		mapTypeControl: false,
		scaleControl: false,
		gestureHandling: 'greedy',
		streetViewControl: false,
		rotateControl: false,
		fullscreenControl: false,
		disableDefaultUi: true, // todo(scd) this is a test
		clickableIcons: false,
		styles: [
			{
				featureType: 'all',
				elementType: 'geometry.fill',
				stylers: [{ weight: '2.00' }],
			},
			{
				featureType: 'all',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9c9c9c' }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text',
				stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text.fill',
				stylers: [{ visibility: 'on' }, { color: '#545859' }],
			},
			{
				featureType: 'landscape',
				elementType: 'all',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#969696' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels.text.stroke',
				stylers: [{ visibility: 'off' }, { color: '#ff0000' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9c9c9c' }],
			},
			{
				featureType: 'poi.attraction',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.business',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.government',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.medical',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ hue: '#00ff74' }, { saturation: '-40' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#444444' }],
			},
			{
				featureType: 'poi.place_of_worship',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.school',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.sports_complex',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road',
				elementType: 'all',
				stylers: [{ saturation: -100 }, { lightness: 45 }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.fill',
				stylers: [{ color: '#dddddd' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
			},
			{
				featureType: 'road',
				elementType: 'labels',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#666666' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }, { visibility: 'off' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'all',
				stylers: [{ visibility: 'simplified' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.text.stroke',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'transit',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'water',
				elementType: 'all',
				stylers: [{ color: '#46bcec' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry.fill',
				stylers: [{ color: '#9dd8e7' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#070707' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }],
			},
		],
	};

	// Tan theme
	if (theme === 'tan') {
		options.styles = [
			{
				elementType: 'labels.text.fill',
				stylers: [{ color: '#3c2a31' }, { saturation: -50 }, { lightness: 25 }],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.fill',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f5ecde' }, { visibility: 'on' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#ede0ca' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry.fill',
				stylers: [{ color: '#ede0ca' }, { visibility: 'on' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f5ecde' }, { lightness: 50 }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#ffffff' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#3c2a31' }],
			},
			{
				featureType: 'transit',
				stylers: [{ visibility: 'off' }],
			},
		];

		// Dark theme for Toronto
	} else if (theme === 'dark') {
		options.styles = [
			{
				elementType: 'geometry',
				stylers: [
					{
						color: '#212121',
					},
				],
			},
			{
				elementType: 'labels.icon',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#757575',
					},
				],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [
					{
						color: '#212121',
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry',
				stylers: [
					{
						color: '#757575',
					},
				],
			},
			{
				featureType: 'administrative.country',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#9e9e9e',
					},
				],
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'administrative.locality',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#bdbdbd',
					},
				],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#757575',
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [
					{
						color: '#181818',
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#616161',
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.stroke',
				stylers: [
					{
						color: '#1b1b1b',
					},
				],
			},
			{
				featureType: 'road',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#2c2c2c',
					},
				],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#8a8a8a',
					},
				],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [
					{
						color: '#373737',
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [
					{
						color: '#3c3c3c',
					},
				],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry',
				stylers: [
					{
						color: '#4e4e4e',
					},
				],
			},
			{
				featureType: 'road.local',
				elementType: 'labels',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#616161',
					},
				],
			},
			{
				featureType: 'transit',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#757575',
					},
				],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [
					{
						color: '#000000',
					},
				],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#3d3d3d',
					},
				],
			},
		];

		// Grayscale for Toronto
	} else if (theme === 'grayscale') {
		options.styles = [
			{
				featureType: 'all',
				elementType: 'geometry.fill',
				stylers: [{ weight: '2.00' }],
			},
			{
				featureType: 'all',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9c9c9c' }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text',
				stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text.fill',
				stylers: [{ visibility: 'on' }, { color: '#545859' }],
			},
			{
				featureType: 'landscape',
				elementType: 'all',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#969696' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels.text.stroke',
				stylers: [{ visibility: 'off' }, { color: '#ff0000' }],
			},
			{
				featureType: 'landscape.natural',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f9f7f8' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f2f0f1' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9c9c9c' }],
			},
			{
				featureType: 'poi',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f2f0f1' }],
			},
			{
				featureType: 'poi.attraction',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.business',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.government',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.medical',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#444444' }],
			},
			{
				featureType: 'poi.place_of_worship',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.school',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'poi.sports_complex',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road',
				elementType: 'all',
				stylers: [{ saturation: -100 }, { lightness: 45 }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.fill',
				stylers: [{ color: '#dddddd' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
			},
			{
				featureType: 'road',
				elementType: 'labels',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#666666' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }, { visibility: 'off' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'all',
				stylers: [{ visibility: 'simplified' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.text.stroke',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'transit',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'water',
				elementType: 'all',
				stylers: [{ hue: '#46bcec' }, { saturation: '-80' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#070707' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }],
			},
		];

		// TERRAIN
	} else if (theme === 'terrain') {
		options.mapTypeId = 'terrain';
		options.styles = [
			{
				featureType: 'all',
				elementType: 'geometry.fill',
				stylers: [{ weight: '2.00' }],
			},
			{
				featureType: 'all',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9c9c9c' }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text',
				stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
			},
			{
				featureType: 'all',
				elementType: 'labels.text.fill',
				stylers: [{ visibility: 'on' }, { color: '#545859' }],
			},
			{
				featureType: 'landscape',
				elementType: 'all',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#969696' }],
			},
			{
				featureType: 'landscape',
				elementType: 'labels.text.stroke',
				stylers: [{ visibility: 'off' }, { color: '#ff0000' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f7f5f6' }],
			},
			{
				featureType: 'landscape.man_made',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#9c9c9c' }],
			},
			{
				featureType: 'poi.attraction',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'poi.business',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'poi.government',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'poi.medical',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ hue: '#00ff74' }, { saturation: '-40' }, { visibility: 'on' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#444444' }, { visibility: 'on' }],
			},
			{
				featureType: 'poi.place_of_worship',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'poi.school',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'poi.sports_complex',
				elementType: 'all',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'road',
				elementType: 'all',
				stylers: [{ saturation: -100 }, { lightness: 45 }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.fill',
				stylers: [{ color: '#dddddd' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
			},
			{
				featureType: 'road',
				elementType: 'labels',
				stylers: [{ visibility: 'on' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#7b7b7b' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }, { visibility: 'off' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'all',
				stylers: [{ visibility: 'simplified' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'transit',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'water',
				elementType: 'all',
				stylers: [{ color: '#46bcec' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry.fill',
				stylers: [{ color: '#9dd8e7' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#070707' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#ffffff' }],
			},
		];

		// POI
	} else if (theme === 'poi') {
		options = {
			zoomControl: true,
			zoomControlOptions: {
				position: 7,
			},
			mapTypeControl: true,
			scaleControl: false,
			scrollwheel: false,
			streetViewControl: true,
			rotateControl: false,
			fullscreenControl: false,
			clickableIcons: false,
			styles: [
				{
					featureType: 'landscape',
					stylers: [{ color: '#f7f5f6' }],
				},
				{
					featureType: 'poi',
					elementType: 'labels.text',
					stylers: [{ visibility: 'off' }],
				},
				{
					featureType: 'poi.business',
					elementType: 'labels.icon',
					stylers: [{ visibility: 'off' }],
				},
				{
					featureType: 'poi.park',
					stylers: [{ visibility: 'simplified' }],
				},
				{
					featureType: 'poi.park',
					elementType: 'labels',
					stylers: [{ visibility: 'simplified' }],
				},
				{
					featureType: 'poi.school',
					stylers: [{ visibility: 'on' }],
				},
				{
					featureType: 'poi.school',
					elementType: 'geometry.fill',
					stylers: [{ color: '#f2d6b5' }],
				},
				{
					featureType: 'poi.school',
					elementType: 'labels.icon',
					stylers: [{ color: '#da7c10' }],
				},
				{
					featureType: 'road',
					stylers: [{ visibility: 'simplified' }],
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [{ saturation: '-100' }, { lightness: '-10' }],
				},
				{
					featureType: 'road',
					elementType: 'labels',
					stylers: [{ saturation: '-100' }, { lightness: '20' }],
				},
				{
					featureType: 'road.local',
					stylers: [{ lightness: '20' }],
				},
				{
					featureType: 'road.local',
					elementType: 'labels',
					stylers: [{ visibility: 'off' }],
				},
				{
					featureType: 'water',
					stylers: [{ saturation: '-15' }, { lightness: '20' }],
				},
				{
					featureType: 'water',
					elementType: 'labels',
					stylers: [{ visibility: 'off' }],
				},
			],
		};
	}
	return options;
};

/**
 * tries to map a genaric placeCollection to the Google place types
 * currently supports: schools, restaurants, shopping if it does not match
 * just returns an array only containing the submitted collection
 * @see https://developers.google.com/maps/documentation/javascript/supported_types
 * @param placeCollection
 * @return {string[]}
 */
const placeTypes = (placeCollection) => {
	console.log('placeTypes', placeCollection);
	switch (placeCollection) {
		case 'schools':
			return ['school', 'secondary_school'];
		case 'restaurants':
			return ['restaurant', 'cafe', 'bakery'];
		case 'shopping':
			return [
				'art_gallery',
				'clothing_store',
				'department_store',
				'electronics_store',
				'furniture_store',
				'hardware_store',
				'home_goods_store',
				'jewelry_store',
				'pet_store',
				'shoe_store',
				'shopping_mall',
				'store',
			];
		default:
			return [placeCollection];
	}
};
export {
	distance,
	metersPerPixel,
	zoomToShowDistance,
	distanceForZoom,
	getLatLngCenter,
	getRadius,
	defaultMapConfiguration,
	placeTypes,
};
