import { slugify } from '@/utilities/stringFormatters'
import { isEmpty } from '@/utilities/helpers'

export const searchWidgetMixin = {
  computed: {
    filteredCities () {
      const regionNames = Object.values(this.regions).map(region => {
        return region.name.toLowerCase()
      })
      if (regionNames.length === 0) {
        return this.cities
      }
      return this.cities.filter(city => {
        const cityString = String(city.city + ', ' + city.state).toLowerCase()
        if (cityString.includes('toronto')) {
          return false
        }
        return !regionNames.includes(cityString)
      })
    },
    linkRefs () {
      const linkRefs = []
      Object.entries(this.regions).forEach(item => {
        const region = item[1]
        linkRefs.push(this.regionRefSlug(region))
        if (this.hasApartmentRef(region)) {
          linkRefs.push(this.regionApartmentRefSlug(region))
        }
      })
      if (this.showCities) {
        this.filteredCities.forEach(city => {
          linkRefs.push(this.cityRefSlug(city))
        })
      }
      if (this.showApartments) {
        Object.entries(this.apartments).forEach(item => {
          const apartment = item[1]
          linkRefs.push(this.apartmentRefSlug(apartment))
        })
      }
      if (this.showCommunities) {
        Object.entries(this.communities).forEach(item => {
          const community = item[1]
          linkRefs.push(this.communityRefSlug(community))
        })
      }
      if (this.showZips) {
        this.zipCodes.forEach(zip => {
          linkRefs.push(this.zipRefSlug(zip))
        })
      }
      Object.entries(this.homesInResults).forEach(item => {
        const home = item[1]
        linkRefs.push(this.homeRefSlug(home))
      })
      if (this.showSearchMore) {
        linkRefs.push('searchMore')
      }
      if (this.showMapSearch) {
        linkRefs.push('mapSearch')
      }
      return linkRefs
    },
    communities () {
      if (typeof this.searchResults.communities !== 'undefined') {
        return this.searchResults.communities
      } else {
        return {}
      }
    },
    apartments () {
      if (typeof this.searchResults.apartments !== 'undefined') {
        return this.searchResults.apartments
      } else {
        return {}
      }
    },
    cities () {
      if (typeof this.searchResults.cities !== 'undefined') {
        return this.searchResults.cities
      } else {
        return []
      }
    },
    zipCodes () {
      if (typeof this.searchResults.zip_codes !== 'undefined') {
        return this.searchResults.zip_codes
      } else {
        return []
      }
    },
    homesInResults () {
      if (typeof this.searchResults.homes !== 'undefined') {
        return this.searchResults.homes
      } else {
        return {}
      }
    },
    homesToList () {
      if (!isEmpty(this.homesInResults) && this.homesInResults.length > 0) {
        return this.homesInResults.slice(0, this.maxResultsDisplay)
      }
      return []
    }
  },
  methods: {
    hasApartmentRef (region) {
      return region.active_apartment_count > 0
      // given that we now return active_apartment_count this is not needed
      // const found = []
      // for (const apartmentsKey in this.apartments) {
      //   if (Object.prototype.hasOwnProperty.call(this.apartments, apartmentsKey)) {
      //     const apartment = this.apartments[apartmentsKey]
      //     if (apartment.region_id.toString() === region.id.toString()) {
      //       found.push(apartment)
      //     }
      //   }
      // }
      // if (!isEmpty(found)) {
      //   return found.length
      // } else {
      //   return false
      // }
    },
    regionRefSlug (region) {
      if (!isEmpty(region) && !isEmpty(region.slug)) {
        return 'region_' + region.slug
      }
      return null
    },
    regionApartmentRefSlug (region) {
      if (!isEmpty(region) && !isEmpty(region.slug)) {
        return 'region_apartment_' + region.slug
      }
      return null
    },
    propertyRefSlug (property) {
      if (typeof property.slug !== 'undefined') {
        return 'property_' + slugify(property.name)
      }
      return 'property_' + slugify(property.name)
    },
    cityRefSlug (city) {
      return 'city_' + slugify(city.city + city.state)
    },
    zipRefSlug (zipCode) {
      return 'zip_' + zipCode.zip
    },
    homeRefSlug (home) {
      return 'home_' + home.slug
    },
    communityRefSlug (community) {
      return 'community_' + community.slug
    },
    apartmentRefSlug (apartment) {
      return 'apartment_' + apartment.slug
    },
    linkForCity (city) {
      console.warn('searchWidget.linkForCity', city, this.getRegion(city.region_id))
      const url = new URL(window.location.href)
      const path = ['find']
      const region = this.getRegion(city.region_id)
      path.push(region.slug)
      path.push(slugify(city.city.toLowerCase() + '-' + city.state.toLowerCase()))
      url.pathname = path.join('/') + '/'
      return url.href
    },
    linkRefIndex () {
      return this.linkRefs.findIndex(ref => {
        let el = this.$refs[ref]
        if (!isEmpty(el) && typeof el.tabindex === 'undefined') {
          el = el[0]
        }
        if (typeof el !== 'undefined') {
          return el.tabindex === 0
        } else {
          return false
        }
      })
    },
    refElement (index) {
      let el = this.$refs[this.linkRefs[index]]
      if (typeof el !== 'undefined' && Array.isArray(el)) {
        el = el[0]
      }
      return el
    },
    currentRefElement () {
      const index = this.linkRefIndex()
      if (index === -1) {
        return null
      }
      return this.refElement(index)
    },
    focusNext (event) {
      // look to see current focus ref
      console.group('focusNext')
      const refIndex = this.linkRefIndex()
      if (refIndex === -1) {
        const el = this.refElement(0)
        if (typeof el !== 'undefined') {
          el.tabindex = 0
          el.focus()
        } else {
          // see if there is a tabbable item in the current document and tab to first one
          var tabables = document.getElementsByClassName('tabable')
          console.log('tabables', tabables, tabables.length)
          if (tabables.length > 0) {
            const nextElement = tabables.item(0)
            nextElement.tabindex = 0
            nextElement.focus()
          }
        }
      } else {
        if (refIndex === this.linkRefs.length - 1) {
          if (event.key === 'Tab') {
            const el = this.refElement(refIndex)
            el.tabindex = -1
            this.$emit('close-results', true)
            const target = document.getElementById('vue-portal-search')
            if (target !== null) {
              const nextSib = target.nextElementSibling
              nextSib.tabindex = 0
              nextSib.focus()
            } else {
              this.$emit('lost-focus', {
                type: 'search',
                event: event
              })
            }
          } else {
            this.refElement(refIndex).tabindex = -1
            const el = this.refElement(0)
            if (typeof el !== 'undefined') {
              el.tabindex = 0
              el.focus()
            } else {
              // see if there is a tabbable item in the current document and tab to first one
              const tabables = document.getElementsByClassName('tabable')
              console.log('tabables', tabables, tabables.length)
              if (tabables.length > 0) {
                const nextElement = tabables.item(0)
                nextElement.tabindex = 0
                nextElement.focus()
              }
            }
          }
        } else {
          const el = this.refElement(refIndex)
          el.tabindex = -1
          const el1 = this.refElement(refIndex + 1)
          if (typeof el1 !== 'undefined') {
            el1.tabindex = 0
            el1.focus()
          } else {
            // see if there is a tabbable item in the current document and tab to first one
            const tabables = document.getElementsByClassName('tabable')
            console.log('tabables', tabables, tabables.length)
            if (tabables.length > 0) {
              const nextElement = tabables.item(0)
              nextElement.tabindex = 0
              nextElement.focus()
            }
          }
        }
      }
      console.groupEnd()
    },
    focusPrevious (event) {
      // look to see current focus ref
      const refIndex = this.linkRefIndex()
      if (refIndex === -1 || refIndex === 0) {
        const el = this.refElement(this.linkRefs.length - 1)
        el.tabindex = 0
        el.focus()
      } else {
        let el = this.refElement(refIndex)
        el.tabindex = -1
        el = this.refElement(refIndex - 1)
        el.tabindex = 0
        el.focus()
      }
    },
    clickRef (event) {
      if (this.currentRefElement() !== null) {
        this.currentRefElement().click()
      }
    }
  }
}
