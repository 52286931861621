/* Randomize array in-place using Durstenfeld shuffle algorithm */
import uniqBy from 'lodash/uniqBy'

const shuffleArray = array => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

/**
 *
 * @param {*[]}arr
 * @param size
 * @return {*}
 */
const getRandomSubarray = (arr, size) => {
  const shuffled = arr.slice(0)
  let i = arr.length
  let temp
  let index
  while (i--) {
    index = Math.floor((i + 1) * Math.random())
    temp = shuffled[index]
    shuffled[index] = shuffled[i]
    shuffled[i] = temp
  }
  return shuffled.slice(0, size)
}

/**
 *
 * @param {*[]} arr
 * @return {*|null}
 */
const getRandomItem = (arr) => {
  if (arr.length === 0) {
    return null
  } else if (arr.length === 1) {
    return arr[0]
  }
  return getRandomSubarray(arr, 1)[0]
}

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} arrayToSearch the array to search.
 * @param {array} wordsToSearch the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
const arrayContainsAny = function (arrayToSearch, wordsToSearch) {
  return wordsToSearch.some(function (v) {
    return arrayToSearch.indexOf(v) >= 0
  })
}

const arrayContainsAnyPartials = (arrayToSearch, wordsToSearch) => {
  const filtered = arrayToSearch.filter(v =>
    wordsToSearch.every(w =>
      v.toLowerCase().split(' ')
        .reduce((isIn, h) => isIn || String(h).indexOf(w) >= 0, false)
    )
  )
  return filtered.length > 0
}

const arrayContainsAll = (arrayToSearch, arrayAll) => {
  return arrayAll.every(element => {
    return arrayToSearch.includes(element)
  })
}

const arrayHaveSameContents = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every(element => {
      if (array2.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}

function chunkArray (array, size) {
  if (array.length <= size) {
    return [array]
  }
  return [array.slice(0, size), ...chunkArray(array.slice(size), size)]
}

const arrayFromRange = (start, end, step = 1) => {
  const output = []
  if (typeof end === 'undefined') {
    end = start
    start = 0
  }
  for (let i = start; i <= end; i += step) {
    output.push(i)
  }
  return output
}

const uniqueArrayByProp = (array, prop) => {
  const uniqByProp = prop => arr =>
    Array.from(
      arr
        .reduce(
          (acc, item) => (
            item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
          new Map()
        )
        .values()
    )
  const uniqueById = uniqByProp(prop)

  return uniqueById(array)
}

const asc = arr => arr.sort((a, b) => a - b)

// sample standard deviation

const quantile = (arr, q) => {
  const sorted = asc(arr)
  const pos = (sorted.length - 1) * q
  const base = Math.floor(pos)
  const rest = pos - base
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base])
  } else {
    return sorted[base]
  }
}

export {
  shuffleArray,
  getRandomSubarray,
  getRandomItem,
  groupBy,
  arrayContainsAny,
  arrayContainsAnyPartials,
  arrayContainsAll,
  arrayHaveSameContents,
  chunkArray,
  arrayFromRange,
  uniqueArrayByProp,
  quantile
}
