// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
const debounce = (func, wait) => {
  let timeout

  // This is the function that is returned and will be executed many times
  // We spread (...args) to capture any number of parameters we want to pass
  return function executedFunction (...args) {
    // The callback function to be executed after
    // the debounce time has elapsed
    const later = () => {
      // null timeout to indicate the debounce ended
      timeout = null

      // Execute the callback
      func(...args)
    }
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout)

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = setTimeout(later, wait)
  }
}

/**
 * Usage (async/await promise):
 * try {
 *     await sleepUntil(() => document.querySelector('.my-selector'), 5000);
 *     // ready
 * } catch {
 *     // timeout
 * }
 * Usage (.then promise):
 * sleepUntil(() => document.querySelector('.my-selector'), 5000)
 *     .then(() => {
 *         // ready
 *     }).catch(() => {
 *         // timeout
 *     });
 *
 * @see https://levelup.gitconnected.com/javascript-wait-until-something-happens-or-timeout-82636839ea93
 * @param f
 * @param timeoutMs
 * @returns {Promise<unknown>}
 */
const sleepUntil = async (f, timeoutMs) => {
  return new Promise((resolve, reject) => {
    const timeWas = new Date()
    const wait = setInterval(function () {
      if (f()) {
        console.log('resolved after', new Date() - timeWas, 'ms')
        clearInterval(wait)
        resolve()
      } else if (new Date() - timeWas > timeoutMs) { // Timeout
        console.log('rejected after', new Date() - timeWas, 'ms')
        clearInterval(wait)
        reject()
      }
    }, 20)
  })
}

export { debounce, sleepUntil }
