import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
// link to formats https://date-fns.org/v2.13.0/docs/format

const DATE_FORMAT_TRIPOD = 'MM/dd/yy'
const DATE_FORMAT_DEFAULT = 'PP'
const DATETIME_FORMAT_DEFAULT = 'p PP'
const TIME_FORMAT_DEFAULT = 'p'
/**
 * returns a string like 5:48 PM MAY 7, 2020
 * @param date String|Date
 * @param dateTimeFormat String
 * @returns {string | *}
 */
const dateTimeString = (date, dateTimeFormat = DATETIME_FORMAT_DEFAULT) => {
  if (typeof date === 'string' && date !== '') {
    return format(parseISO(date), dateTimeFormat)
  } else if (typeof date === 'object') {
    try {
      return format(date, dateTimeFormat)
    } catch {
      return format(new Date(), dateTimeFormat)
    }
  }
  return format(new Date(), dateTimeFormat)
}

/**
 * returns a string like MAY 7, 2020
 * @param date Date|String
 * @param dateFormat String
 * @returns {string | *}
 */
const dateString = (date, dateFormat = DATE_FORMAT_DEFAULT) => {
  if (typeof date === 'string' && date !== '') {
    return format(parseISO(date), dateFormat)
  } else if (typeof date === 'object') {
    try {
      return format(date, dateFormat)
    } catch {
      return format(new Date(), dateFormat)
    }
  }
  return format(new Date(), dateFormat)
}

/**
 * returns a string like 5:48 PM
 * @param time string|date
 * @param timeFormat String
 * @returns {string | *}
 */
const timeString = (time, timeFormat = TIME_FORMAT_DEFAULT) => {
  if (typeof time === 'string' && time !== '') {
    return format(parseISO(time), timeFormat)
  } else if (typeof time === 'object') {
    try {
      return format(time, timeFormat)
    } catch {
      return format(new Date(), timeFormat)
    }
  }
  return format(new Date(), timeFormat)
}

const isISOFormattedDate = (dateString) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateString)) return false
  var d = new Date(dateString)
  return d.toISOString() === dateString
}

const notISODateParse = (str) => {
  if (/\d{4}-\d{2}-\d{2}/.test(str)) {

  }
}

/**
 * takes a UTC date time string and timeshifts it forward by the browser timezone
 *
 * this is needed to work around that Tricon in the pricing matrix feed provides
 * something they intend to be a pure `date` as a ISO 8601 date time string with
 * the time zone set to UTC so when javascript parses to a date object it is essentially
 * off by a day, this compensates for this passing of a `date` as a date time ISO string
 * @see https://app.clickup.com/t/863geh45e
 * @param dateString
 * @return {Date | *}
 */
const dateFromUTC = (dateString) => {
  const date = parseISO(dateString)
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
  return date
}

export { dateTimeString, dateString, timeString, isISOFormattedDate, dateFromUTC, DATE_FORMAT_TRIPOD }
