import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueWait from 'vue-wait'
import * as GmapVue from 'gmap-vue'
import VueScrollTo from 'vue-scrollto'
import VueFriendlyIframe from 'vue-friendly-iframe'
import IdleVue from 'idle-vue'
import { saveTokens } from '@/api/http-utils'
import { addMarketingParamsToPageUrls } from '@/utilities/marketing'
import VueGeolocation from 'vue-browser-geolocation'
import breakpoint from 'better-vue-breakpoints'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import get from 'lodash/get'
/**
 * sentry
 */
import * as Sentry from '@sentry/vue'
import { sentryFilter } from '@/data/sentry_ignore'
import { setTREnvironment } from '@/utilities/buildEnvironmentValues'
setTREnvironment()
let lastTag = 'local'
let buildTime = (new Date()).toISOString()
if (typeof process.env.MIX_LAST_TAG !== 'undefined') {
  lastTag = process.env.MIX_LAST_TAG
}
if (typeof process.env.MIX_BUILD_TIME !== 'undefined') {
  buildTime = process.env.MIX_BUILD_TIME
}
console.warn(`Version: ${buildTime} (${lastTag})`)
const SENTRY_SAMPLE_RATE = (process.env.MIX_SENTRY_ENVIRONMENT !== 'production') ? 1 : 0.01
Sentry.init({
  Vue,
  // dsn: process.env.MIX_SENTRY_SEARCH_DSN,
  logErrors: true,
  // integrations: [
  //   new BrowserTracing({
  //     tracingOrigins: ['localhost', 'triconresidential.test', 'triconresidential.com', 'tricon.creativeoven.com', /^\//]
  //   })
  // ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  sampleRate: 0.01,
  environment: process.env.MIX_SENTRY_ENVIRONMENT,
  release: lastTag,
  ignoreErrors: sentryFilter.ignoreErrors,
  denyUrls: sentryFilter.denyUrls
})
Vue.use(PortalVue)
saveTokens()
/**
 * this step is now handled by the code from the common marketing core on the main web page
 * so that even if pages end up having no VueJs code or it fails to load marketing is still
 * captured in a consistent way
storeMarketing()
 */

// todo:(scd) this may need to fire on a different event later due to the common marketing firing earlier...
window.addEventListener('load', function () {
  addMarketingParamsToPageUrls()
})

Vue.use(IdleVue, {
  store: store,
  idleTime: 1000 * 60 * 15, // 15 min
  startAtIdle: false
})
Vue.use(VueFriendlyIframe)
Vue.use(VueWait)
Vue.use(GmapVue, {
  load: {
    key: process.env.MIX_GOOGLE_MAP_API_KEY,
    libraries: 'places,drawing' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'gmap-vue/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then set installComponents to 'false'.
  /// / If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})
// Showing the default settings, these can
// be adjusted when called or here
// https://github.com/rigor789/vue-scrollto
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(VueGeolocation)
// if you want to use custom breakpoints https://github.com/butaminas/better-vue-breakpoints
Vue.use(breakpoint, {
  xs: 480,
  sm: 768, // mobile
  md: 1024,
  lg: 1200,
  xl: 1320
})

Vue.use(VueMeta)
Vue.config.productionTip = false
// Vue.component('homes-block', () => import(/* webpackChunkName: "home-carousel" */'./components/homes_block/HomesBlock.vue'))
// Vue.component('search-widget', () => import(/* webpackChunkName: "search-widget" */'./components/search_widget/SearchWidget.vue'))
// Vue.component('advanced-search', () => import(/* webpackChunkName: "advanced-search" */'./components/advanced_search/AdvancedSearch.vue'))
// Vue.component('neighborhood-map', () => import(/* webpackChunkName: "neighborhood-map" */'./components/neighborhood_map/NeighborhoodMap.vue'))
// Vue.component('apartments-block', () => import(/* webpackChunkName: "apartments-block" */'./components/apartments_block/ApartmentsBlock'))
// Vue.component('debug-utm', () => import(/* webpackChunkName: "debug-utm" */'./components/debug/DebugUtm.vue'))

/**
 * add a $isEUVisitor to components given header value provided by Pagely
 * @type {boolean}
 */
Vue.prototype.$isEUVisitor = get(window, '__INITIAL_SETTINGS__.location.is_eu', false)
console.log('Vue.prototype.$isEUVisitor', Vue.prototype.$isEUVisitor)

new Vue({
  store,

  wait: new VueWait({
    // Defaults values are following:
    useVuex: true, // Uses Vuex to manage wait state
    vuexModuleName: 'wait', // Vuex module name

    registerComponent: true, // Registers `v-wait` component
    componentName: 'v-wait', // <v-wait> component name, you can set `my-loader` etc.

    registerDirective: true, // Registers `v-wait` directive
    directiveName: 'wait' // <span v-wait /> directive name, you can set `my-loader` etc.

  }),
  render: h => h(App)
}).$mount('#loadVue')
