import Cookies from 'js-cookie'
import { isEmpty } from '@/utilities/helpers'
import { ignoreProtocol, treatAsSelfDomains } from '@/data/treat_as_self_domains'
import { referrerMapping } from '@/data/referrer_mapping'
import { sourceRewrites } from '@/data/rewrite_marketing'
import { getWithExpiry, setWithExpiry } from '@/utilities/localStorageWithExpiration'

// custom events
const TRICON_MARKETING_EVENT_LOADED = 'TRICON_MARKETING_EVENT_LOADED'
const TRICON_MARKETING_EVENT_STORED = 'TRICON_MARKETING_EVENT_STORED'
const DEFAULT_SOURCE = 'direct'
const shouldMapReferrer = false
const shouldAddUnmappedInOutput = false // 2022-06-26 (SCD) do not map per requests

/**
 * list of keys to use for finding parameters in urls, writing to outbound links and as parts of keys in the cookie store
 * @type {string[]}
 */
const marketingKeys = [
  'source',
  'medium',
  'campaign',
  'term',
  'keyword',
  'adgroup',
  'adcopy',
  'content', // map to adcopy
  'device'
]

const platformKeys = [
  'fbclid',
  'glcid'
]

/**
 * prefix of param keys for storing initial marketing information. never overridden
 * @type {string}
 */
const TRICON_INITIAL_PREFIX = 'tricon_'

/**
 * prefix of param keys for storing converting landed marketing information
 * @type {string}
 */
const TRICON_CONVERTING_PREFIX = 'tricon_converting_'

/**
 * right now we prepend links with 'tricon_' marketing keys
 * 2021-09-15 (SCD) was changed from blank to support sharing code with secure.triconresidential.com
 *     and the issue with WordPress not handling term and keyword parameters gracefully
 * @type {string}
 */
const outboundPrefix = 'tricon_'

/**
 * prefix for platform click ids since analytics may add this unprefixed
 * @type {string}
 */
// eslint-disable-next-line no-unused-vars
const outboundPlatformPrefix = 'platform_'
/**
 * right now we append links with unprefixed marketing keys
 * @type {string}
 */

// eslint-disable-next-line no-unused-vars
const outboundConvertingPrefix = 'active_'

/**
 * used for postfixing when mapped referrer is used
 * @type {string}
 */
const unMappedPostFix = '_unmapped'
/**
 * this is an ordered list of inbound prefixes
 * @type {string[]}
 */
const inboundPrefixes = [
  '',
  'tricon_',
  'crm_',
  'utm_'
]

const getCookieBaseDomain = () => {
  const host = document.location.host
  const hostParts = host.split('.')
  console.log('getCookieBaseDomain', host, hostParts, hostParts.slice(-2).join('.'))
  return hostParts.slice(-2).join('.')
}
/**
 * how long to set the expiration for marketing data in the cookie store
 * @type {number}
 */
const initialCookieExpirationInDays = 30
const initialCookieOptions = {
  sameSite: 'lax',
  secure: true,
  expires: initialCookieExpirationInDays,
  domain: getCookieBaseDomain(),
  path: '/'
}

/**
 * how long to set the expiration for the last seen marketing data in the cookie store
 * @type {null|number}
 */
const convertingCookieExpirationInDays = null
const convertingCookieOptions = {
  sameSite: 'lax',
  secure: true,
  expires: convertingCookieExpirationInDays,
  domain: getCookieBaseDomain(),
  path: '/'
}

/**
 *
 * @param {URL|null} url if not supplied the window.location will be used instead
 * @returns {{initial: {Campaign: *, Keyword: *, AdCopy: *, Device: *, Medium, AdGroup, Source}, converting: {Campaign: *, Keyword: *, AdCopy: *, Device: *, Medium, AdGroup, Source}}}
 */
const storeMarketing = (url = null) => {
  console.group('storeMarketing')

  if (url === null) {
    url = new URL(window.location.href)
  }
  console.log('storeMarketing(' + url.href + ')')
  console.log('initialCookieOptions', initialCookieOptions)
  console.log('convertingCookieOptions', convertingCookieOptions)
  let referrer = document.referrer
  // fixme: (SCD) remove this after testing
  if (url.searchParams.has('fake_referrer')) {
    console.error('Modified referrer for testing! remove code below comment')
    referrer = url.searchParams.get('fake_referrer')
    try {
      // generally speaking people will not be entering valid urls so we might need
      // to compensate
      // eslint-disable-next-line no-unused-vars
      const testURL = new URL(referrer)
    } catch {
      // probably need to add 'https://'
      referrer = 'https://' + referrer
    }
  }
  const searchParams = searchParamsFromUrl(url)
  let referrerURL = null
  if (referrer !== '') {
    // don't try to capture if referer is empty
    referrerURL = new URL(referrer)
  }
  console.log('marketing initial landing url', url.href, url)
  console.log('referrer', referrer, referrerURL)

  // if (referrerURL !== null && (referrerIsSelf(referrerURL) || referrerTreatAsSelf(referrerURL))) {
  //   // don't capture
  //   console.warn('referrer == window.location or referrer is treated as self', referrerURL, url)
  //   console.groupEnd()
  //   return
  // }
  const inboundPrefix = urlHasMarketingArguments(url)
  console.log('inboundPrefix', inboundPrefix)
  if (inboundPrefix || inboundPrefix === '') {
    // check if initial marketing data captured
    const tested = hasMarketingInCookies(TRICON_INITIAL_PREFIX)
    console.log('tested', tested, hasMarketingInCookies(TRICON_INITIAL_PREFIX))
    if (!hasMarketingInCookies(TRICON_INITIAL_PREFIX)) {
      storeMarketingArguments(searchParams, inboundPrefix, TRICON_INITIAL_PREFIX, initialCookieOptions)
      storePlatformArguments(searchParams, TRICON_INITIAL_PREFIX, initialCookieOptions)
      // todo: (SCD) not sure about this still
      // backfillMarketing(url, inboundPrefix, TRICON_INITIAL_PREFIX, initialCookieExpirationInDays)
    } else {
      clearMarketingCookies(TRICON_CONVERTING_PREFIX)
      storeMarketingArguments(searchParams, inboundPrefix, TRICON_CONVERTING_PREFIX, convertingCookieOptions)
      storePlatformArguments(searchParams, TRICON_CONVERTING_PREFIX, convertingCookieOptions)
      // backfillMarketing(url, inboundPrefix, lastPrefix, lastCookieExpirationInDays)
    }
  } else {
    if (referrerURL !== null && (referrerIsSelf(referrerURL) || referrerTreatAsSelf(referrerURL))) {
      // don't capture
      console.warn('referrer == window.location or referrer is treated as self', referrerURL, url)
      console.groupEnd()
      return
    }
    storeReferrerAsMarketing(referrer, initialCookieOptions)
  }
  const marketing = {
    initial: getMarketingObject(TRICON_INITIAL_PREFIX),
    converting: getMarketingObject(TRICON_CONVERTING_PREFIX),
    unmapped: {
      initial: getMarketingAsStruct(TRICON_INITIAL_PREFIX, ''),
      converting: getMarketingAsStruct(TRICON_CONVERTING_PREFIX, '')
    }
  }
  var storedEvent = new CustomEvent(TRICON_MARKETING_EVENT_STORED, {
    detail: {
      marketing: marketing
    }
  })
  document.dispatchEvent(storedEvent)
  console.groupEnd()
  return marketing
}

const referrerIsSelf = (referrer) => {
  const url = new URL(window.location.href)
  return (referrer !== null && referrer.host === url.host && referrer.protocol === url.protocol)
}

/**
 *
 * @param  referrer URL
 * @return {boolean}
 */
const referrerTreatAsSelf = (referrer) => {
  // check host against whitelistDomains
  let host = referrer.host
  host = host.toLowerCase()
  if (treatAsSelfDomains.includes(host)) {
    return true
  } else {
    const found = treatAsSelfDomains.find(domain => {
      const firstChar = domain.slice(0, 1)
      if (firstChar === '*') {
        const testString = domain.slice(1)
        return host.endsWith(testString)
      } else {
        // test exact match
        return host === domain
      }
    })
    if (found) {
      return true
    }
  }
  // check scheme against whitelistSchemes
  const protocol = referrer.protocol.toLowerCase()
  return ignoreProtocol.includes(protocol)
}

/**
 * to do marketing requested remapping for referrer based source and medium the mappings are
 * stored in @/data/referrer_mapping.js
 * based on the data in https://docs.google.com/spreadsheets/d/1ypg2blpf5PUJW546ERxYGJad3sQeKGWcff3nvBBlDso/edit?ts=60660a10#gid=1313164338
 * @param referrer
 * @returns {{source, medium: string, source_unmapped, medium_unmapped: string}}
 */
const mapRefererToMarketing = (referrer) => {
  let referrerUrl = null
  const result = {
    source: referrer,
    medium: 'referrer',
    source_unmapped: referrer,
    medium_unmapped: 'referrer'
  }
  try {
    referrerUrl = new URL(referrer)
  } catch (e) {
    return result
  }
  result.source = referrerUrl.host
  result.source_unmapped = referrerUrl.host
  const host = referrerUrl.host.toLowerCase()
  console.log('mapReferrerToMarketing', referrer, referrerUrl, host)
  // try all the fullSearch values first because they are an order of magnitude faster to do
  for (const mediumValue in referrerMapping) {
    const fullSearchHosts = referrerMapping[mediumValue].fullSearch
    if (fullSearchHosts.includes(host)) {
      result.medium = mediumValue
      result.source = host
      return result
    }
  }
  // try all the findMatch values
  for (const mediumValue in referrerMapping) {
    const patternMatchHosts = referrerMapping[mediumValue].patternMatch
    for (const pattern of patternMatchHosts) {
      const regexPattern = pattern.replace(/\*/gi, '(.*)')
      const re = new RegExp(regexPattern, 'i')
      console.log('match', pattern, regexPattern, host, host.match(re), re.test(host))
      if (re.test(host)) {
        result.medium = mediumValue

        result.source = host
      }
    }
  }
  return result
}
// todo: (SCD) this really should be done on ingest since it is expensive and should only be done once
const getMappedMarketingValues = (prefix = TRICON_INITIAL_PREFIX) => {
  const result = {
    source: getMarketingValue('source', prefix, DEFAULT_SOURCE),
    medium: getMarketingValue('medium', prefix, null),
    campaign: getMarketingValue('campaign', prefix, null),
    term: getMarketingValue('term', prefix, null),
    keyword: getMarketingValue('keyword', prefix, null),
    adgroup: getMarketingValue('adgroup', prefix, null),
    content: getMarketingValue('content', prefix, null),
    adcopy: getMarketingValue('adcopy', prefix, null),
    device: getMarketingValue('device', prefix, null)
  }
  if (isEmpty(result.adcopy)) {
    result.adcopy = getMarketingValue('content', prefix, null)
  }
  if (getMarketingValue('source_unmapped', prefix, DEFAULT_SOURCE) !== DEFAULT_SOURCE) {
    result.source_unmapped = getMarketingValue('source_unmapped', prefix, DEFAULT_SOURCE)
  }
  return result
}

/**
 * so there seems to be little to no support for searchParams in IE so probably will
 * have to use this to do a kind of pollyfill
 * todo: (SCD) create a polyfill for ie
 * @param urlString
 * @return {{}}
 */
const searchParamsFromUrl = (urlString) => {
  const url = new URL(urlString)
  const searchParams = url.searchParams
  const params = {}
  for (const [key, value] of searchParams) {
    params[key] = value
  }
  return params
}

/**
 * does the inbound url have any marketing prefixes defined by the unPrefixedMap id parameters
 * with the inboundPrefixes array, it returns the first prefix that matches or false if no matches
 * @param url
 * @return {boolean|string}
 */
const urlHasMarketingArguments = (url) => {
  const searchParams = url.searchParams
  for (const prefix of inboundPrefixes) {
    const hasAny = marketingKeys.find(key => {
      return searchParams.has(prefix + key)
    })
    if (hasAny) {
      // console.log('return', prefix, hasAny)
      return prefix
    }
  }
  return false
}

/**
 * checks the cookies store for the marketing parameters existing
 * @param prefix
 * @return {boolean}
 */
const hasMarketingInCookies = (prefix) => {
  const hasAnyInCookieStore = marketingKeys.find(key => {
    const prefixedKey = prefix + key
    console.log('hasMarketingInCookies', prefix, key, getWithExpiry(prefixedKey))
    return getWithExpiry(prefixedKey)
  })
  console.log('hasMarketingInCookies', prefix, hasAnyInCookieStore, isEmpty(hasAnyInCookieStore))
  return !isEmpty(hasAnyInCookieStore)
}

const hasPlatformInCookies = (prefix) => {
  const hasAnyInCookieStore = platformKeys.find(key => {
    const prefixedKey = prefix + key
    console.log('hasPlatformInCookies', prefix, key, getWithExpiry(prefixedKey))
    return getWithExpiry(prefixedKey)
  })
  console.log('hasPlatformInCookies', prefix, hasAnyInCookieStore, isEmpty(hasAnyInCookieStore))
  return !isEmpty(hasAnyInCookieStore)
}

/**
 * store arguments like fbcid and gclid for future passing forward
 * @param searchParams
 * @param prefix
 * @param cookieOptions
 * @return {boolean}
 */
const storePlatformArguments = (searchParams, prefix, cookieOptions) => {
  let expiration = initialCookieExpirationInDays
  if (hasPlatformInCookies(prefix)) {
    if (prefix === TRICON_INITIAL_PREFIX) {
      return false
    } else {
      // clear platform cookies for prefix
      clearPlatformCookies(TRICON_CONVERTING_PREFIX)
    }
  }
  if (TRICON_INITIAL_PREFIX !== prefix) {
    expiration = convertingCookieExpirationInDays
  }
  let storedKeys = false
  const searchParamKeys = Object.keys(searchParams)
  platformKeys.forEach(key => {
    if (searchParamKeys.includes(key)) {
      const storageKey = prefix + key
      setWithExpiry(storageKey, searchParams[key], expiration, cookieOptions)
      storedKeys = true
    }
  })
  return storedKeys
}

const storeMarketingArguments = (searchParams, paramPrefix, cookiePrefix, cookieOptions) => {
  let expiration = initialCookieExpirationInDays
  if (hasMarketingInCookies(cookiePrefix)) {
    if (TRICON_INITIAL_PREFIX === cookiePrefix) {
      // early exit since we only do this once, may be already tested prior to call
      // so this may be redundant
      return false
    } else {
      // clear existing last marketing cookie store
      clearMarketingCookies(paramPrefix)
    }
  }
  if (TRICON_INITIAL_PREFIX !== cookiePrefix) {
    expiration = convertingCookieExpirationInDays
  }
  let storedKeys = false
  const searchParamKeys = Object.keys(searchParams)
  let hadSource = false
  let hadMedium = false
  marketingKeys.forEach(key => {
    const paramKey = paramPrefix + key
    if (searchParamKeys.includes(paramKey)) {
      const cookieKey = cookiePrefix + key
      let value = searchParams[paramKey]
      console.log('storeConvertingMarketingArguments', cookieKey, value, cookieOptions)
      if (key === 'source' && !isEmpty(sourceRewrites[value])) {
        value = sourceRewrites[value]
      }
      setWithExpiry(cookieKey, value, expiration, cookieOptions)
      storedKeys = true
      if (key === 'source') {
        hadSource = true
      }
      if (key === 'medium') {
        hadMedium = true
      }
    }
  })
  if (storedKeys && !hadSource) {
    setWithExpiry(cookiePrefix + 'source', 'empty_source', expiration, cookieOptions)
  }
  if (storedKeys && !hadMedium) {
    setWithExpiry(cookiePrefix + 'medium', 'empty_medium', expiration, cookieOptions)
  }
  return storedKeys
}

const storeReferrerAsMarketing = (referrer, cookieOptions = null) => {
  // if there were no incoming marketing searchParams and there are not already some values
  // if there is a referrer then store referrer in tricon_source and 'referral' in tricon_medium

  // else store 'none' in tricon_source
  const hasAnyInInitialCookieStore = hasMarketingInCookies(TRICON_INITIAL_PREFIX)
  const hasAnyInConvertingCookieStore = hasMarketingInCookies(TRICON_CONVERTING_PREFIX)
  console.log('storeReferrerAsMarketing.referrer', referrer, referrer !== '')
  console.log('storeReferrerAsMarketing.hasAnyInCookieStore (initial, last)', hasAnyInInitialCookieStore, hasAnyInConvertingCookieStore)
  let prefix = null
  let expiration = initialCookieExpirationInDays
  if (!hasAnyInInitialCookieStore) {
    prefix = TRICON_INITIAL_PREFIX
  } else if (!hasAnyInConvertingCookieStore) {
    prefix = TRICON_CONVERTING_PREFIX
    expiration = convertingCookieExpirationInDays
  }
  if (!isEmpty(prefix)) {
    if (referrer !== '') {
      if (shouldMapReferrer) {
        const mappedReferrer = mapRefererToMarketing(referrer)
        console.log('set source to referrer', prefix, mappedReferrer)
        setWithExpiry(prefix + 'source', mappedReferrer.source, expiration, cookieOptions)
        setWithExpiry(prefix + 'medium', mappedReferrer.medium, expiration, cookieOptions)
        if (mappedReferrer.source !== mappedReferrer.source_unmapped) {
          setWithExpiry(prefix + 'source' + unMappedPostFix, mappedReferrer.source_unmapped, expiration, cookieOptions)
          setWithExpiry(prefix + 'medium' + unMappedPostFix, mappedReferrer.medium_unmapped, expiration, cookieOptions)
        }
      } else {
        setWithExpiry(prefix + 'source', referrer, expiration, cookieOptions)
        setWithExpiry(prefix + 'medium', 'referrer', expiration, cookieOptions)
      }
    } else {
      console.log('set source to direct')
      if (expiration !== null) {
        setWithExpiry(prefix + 'source', DEFAULT_SOURCE, expiration, cookieOptions)
        setWithExpiry(prefix + 'medium', DEFAULT_SOURCE, expiration, cookieOptions)
        setWithExpiry(prefix + 'adcopy', referrer, expiration, cookieOptions)
        setWithExpiry(prefix + 'adgroup', 'landing: ' + window.location.href, expiration, cookieOptions)
      } else {
        setWithExpiry(prefix + 'source', DEFAULT_SOURCE, 0, cookieOptions)
        setWithExpiry(prefix + 'medium', DEFAULT_SOURCE, 0, cookieOptions)
        setWithExpiry(prefix + 'adcopy', referrer, 0, cookieOptions)
        setWithExpiry(prefix + 'adgroup', 'landing: ' + window.location.href, 0, cookieOptions)
      }
    }
  }
}

/**
 * clear the cookies of any existing marketing tracking values, should probably no be used for intialPrefix since
 * these are expected to persist always.
 * // todo:(scd) should this early exit is prefix === TRICON_INITIAL_PREFIX?
 * @param prefix String this is used to clear existing cookies
 */
const clearMarketingCookies = (prefix = TRICON_INITIAL_PREFIX) => {
  marketingKeys.forEach(key => {
    // clear all existing utm.store cookies (these are stored as tricon_*)
    Cookies.remove(prefix + key)
    localStorage.removeItem(prefix + key)
  })
  // this one we may store just for ourselves
  Cookies.remove(prefix + 'referrer')
  localStorage.removeItem(prefix + 'referrer')
}

/**
 * clear the cookies of any existing marketing tracking values, should probably no be used for intialPrefix since
 * these are expected to persist always.
 * // todo:(scd) should this early exit is prefix === TRICON_INITIAL_PREFIX?
 * @param prefix String this is used to clear existing cookies
 */
const clearPlatformCookies = (prefix = TRICON_CONVERTING_PREFIX) => {
  platformKeys.forEach(key => {
    // clear all existing utm.store cookies (these are stored as tricon_*)
    Cookies.remove(prefix + key)
  })
}

/**
 * this is a really bad practice desired by SEO folks
 *
 * @param url
 * @param usedPrefix
 * @param storagePrefix
 * @param expirationInDays
 * @deprecated really should not do this
 */
// eslint-disable-next-line no-unused-vars
const backfillMarketing = (url, usedPrefix, storagePrefix, expirationInDays = convertingCookieExpirationInDays, cookieOptions = null) => {
  const searchParams = url.searchParams
  marketingKeys.forEach(key => {
    // if no cookie for argument then look for other prefixed values in the searchParams
    // and backfill
    const cookie = getWithExpiry(storagePrefix + key)
    if (isEmpty(cookie)) {
      for (const prefix of inboundPrefixes) {
        if (searchParams.has(prefix + key)) {
          if (expirationInDays !== null) {
            setWithExpiry(storagePrefix + key, searchParams.get(prefix + key), expirationInDays, cookieOptions)
          } else {
            setWithExpiry(storagePrefix + key, searchParams.get(prefix + key), 0, cookieOptions)
          }
        }
      }
    }
  })
}

/**
 * looks outside the vue components and attempts to add marketing information to urls with the class .addTripodUTM
 * @param className
 */
const addMarketingParamsToPageUrls = (className = 'addTripodUTM') => {
  const elements = Array.from(document.getElementsByClassName(className))
  console.log('addMarketingParamsToPageUrls.elements', elements)
  elements.forEach(function (element) {
    if ('utm_source_default' in element.dataset) {
      element.href = addMarketingParameters(element.href, element.dataset.utm_source_default)
    } else {
      element.href = addMarketingParameters(element.href)
    }
  })
}

/**
 * given source url add the initial marketing parameters using the outboundPrefix
 * @param sourceUrl
 * @param defaultSource
 * @return {string}
 */
const addMarketingParameters = (sourceUrl, defaultSource = DEFAULT_SOURCE) => {
  const storedMarketing = getMappedMarketingValues(TRICON_INITIAL_PREFIX)
  try {
    const url = new URL(sourceUrl)
    let didSetSource = false
    let didSetMedium = false
    // add initial marketing values
    marketingKeys.forEach(key => {
      const param = storedMarketing[key]
      if (!isEmpty(param)) {
        if (key === 'source') {
          // url.searchParams.append('source', param)
          didSetSource = true
        }
        if (key === 'medium') {
          didSetMedium = true
        }
        url.searchParams.append(outboundPrefix + key, param)
      }
    })
    if (shouldAddUnmappedInOutput) {
      const param = storedMarketing.source_unmapped
      if (!isEmpty(param)) {
        url.searchParams.append(outboundPrefix + 'source_unmapped', param)
      }
    }
    if (!didSetSource) {
      // url.searchParams.append('source', defaultSource)
      url.searchParams.append(outboundPrefix + 'source', DEFAULT_SOURCE)
      if (!didSetMedium) {
        url.searchParams.append(outboundPrefix + 'medium', DEFAULT_SOURCE)
      }
    }
    return url.href
  } catch {
    const url = sourceUrl
    const args = []
    let didSetSource = false
    let didSetMedium = false
    marketingKeys.forEach(key => {
      const param = storedMarketing[key]
      if (!isEmpty(param)) {
        if (key === 'source') {
          // url.searchParams.append('source', param)
          didSetSource = true
        }
        if (key === 'medium') {
          // url.searchParams.append('source', param)
          didSetMedium = true
        }
        args.push(outboundPrefix + key + '=' + encodeURIComponent(param))
      }
    })
    if (shouldAddUnmappedInOutput) {
      const param = storedMarketing.source_unmapped
      if (!isEmpty(param)) {
        args.push(outboundPrefix + 'source_unmapped' + '=' + encodeURIComponent(param))
      }
    }
    if (!didSetSource) {
      args.push('source' + '=' + encodeURIComponent(DEFAULT_SOURCE))
      if (!didSetMedium) {
        args.push('medium' + '=' + encodeURIComponent(DEFAULT_SOURCE))
      }
    }
    return url + '?' + args.join('&')
  }
}

/**
 * currently adds initial marketing parameters only
 * @return {string}
 */
const getMarketingAsString = () => {
  const marketing = []
  const storedMarketing = getMappedMarketingValues(TRICON_INITIAL_PREFIX)

  for (const key of marketingKeys) {
    marketing.push(storedMarketing[key])
  }
  // this does not add unmapped versions
  if (marketing.length === 0) {
    marketing.push('none')
  }
  return marketing.join('|')
}

/**
 * used for getting the value of the marketing parameter and returning a default value if empty
 * @param key
 * @param storagePrefix
 * @param defaultValue
 * @return {null|*}
 */
const getMarketingValue = (key, storagePrefix = TRICON_INITIAL_PREFIX, defaultValue = null) => {
  const param = getWithExpiry(storagePrefix + key)
  if (typeof param !== 'undefined' && param !== null) {
    return param
  }
  return defaultValue
}

/**
 * returns marketing as an object used in posting to our APIs (like for KMI and AgentInquiry
 @param  {string} inPrefix default TRICON_INTITIAL_PREFIX also takes TRICON_CONVERTING_PREFIX
 @param  {string} outPrefix outPrefix default `utm_` used when outbound links need a prefix
 @param  {boolean} includeBareSource default true to include source as a key if outPrefix !==
 * @return {{utm_campaign: *, utm_medium, utm_device: *, utm_adgroup: *, source, utm_keyword: *, utm_source, utm_content: *}}
 */
const getMarketingAsStruct = (inPrefix = TRICON_INITIAL_PREFIX, outPrefix = 'utm_', includeBareSource = true) => {
  const storedMarketing = getMappedMarketingValues(inPrefix)
  const marketingStruct = {}
  for (const storedMarketingKey in storedMarketing) {
    marketingStruct[outboundPrefix + storedMarketingKey] = storedMarketing[storedMarketingKey]
  }
  if (includeBareSource) {
    marketingStruct.source = storedMarketing.source
  }
  // 2022-06-19 (SCD) no longer mapping so this is currently false
  if (shouldAddUnmappedInOutput) {
    const param = storedMarketing.source_unmapped
    if (!isEmpty(param)) {
      marketingStruct.source_unmapped = param
    }
  }
  return marketingStruct
}

/**
 * returns the initial marketing data for use in favorites
 * @return {{Campaign: *, Keyword: *, AdCopy: *, Device: *, Medium, AdGroup, Source}}
 */
const getMarketingObject = (prefix = TRICON_INITIAL_PREFIX) => {
  const storedMarketing = getMappedMarketingValues(prefix)
  const marketingObject = {
    Source: storedMarketing.source,
    Campaign: storedMarketing.campaign,
    // 2021-04-12 (SCD) use 'marketing' for group per stringcan/kyle?????
    // 2022-06-28 (SCD) stop mapping marketing into AdGroup
    AdGroup: storedMarketing.adgroup,
    Keyword: storedMarketing.keyword,
    // (SCD) adcopy is overriden by content for favorites (content is used in other
    // contexts rather than adcopy) (confirmed at 2022-06-28 (SCD))
    // 2022-09-14 (SCD) stop mapping content to adcopy it is done when adcopy is empty in getMappedMarktingValues()
    AdCopy: storedMarketing.adcopy,
    Content: storedMarketing.content,
    Device: storedMarketing.device,
    Medium: storedMarketing.medium
  }
  // 2022-06-19 (SCD) no longer mapping so this is currently false
  if (shouldAddUnmappedInOutput) {
    const param = storedMarketing.source_unmapped
    if (!isEmpty(param)) {
      marketingObject.source_unmapped = param
    }
  }
  return marketingObject
}

const getMarketingSource = () => {
  return getMarketingValue('source', TRICON_INITIAL_PREFIX, DEFAULT_SOURCE)
}

const setMeta = (property, content) => {
  const tag = document.querySelector(`meta[property="${property}"]`)
  if (tag !== null) {
    tag.setAttribute('content', content)
  } else {
    var meta = document.createElement('meta')
    meta.setAttribute('property', property)
    meta.content = content
    document.getElementsByTagName('head')[0].appendChild(meta)
  }
}

/**
 * updates the link canonical in the page to the url submitted
 * @param url
 */
const updateLinkCanonical = (url) => {
  var link = document.querySelector('link[rel=\'canonical\']') ? document.querySelector('link[rel=\'canonical\']') : document.createElement('link')
  link.setAttribute('rel', 'canonical')
  link.setAttribute('href', url)
  document.head.appendChild(link)
}

export {
  getMarketingValue,
  inboundPrefixes,
  storeMarketing,
  clearMarketingCookies,
  addMarketingParameters,
  getMarketingObject,
  getMarketingAsString,
  getMarketingSource,
  getMarketingAsStruct,
  getMappedMarketingValues,
  addMarketingParamsToPageUrls,
  searchParamsFromUrl,
  setMeta,
  updateLinkCanonical,
  // events
  TRICON_MARKETING_EVENT_LOADED,
  TRICON_MARKETING_EVENT_STORED,
  // constants
  TRICON_CONVERTING_PREFIX,
  TRICON_INITIAL_PREFIX,
  // for testing
  storeReferrerAsMarketing,
  marketingKeys
}
