import Vue from 'vue'
import Vuex from 'vuex'
// import VuexORM from '@vuex-orm/core'
// import database from '../database'
// import VuexORMSearch from '@vuex-orm/plugin-search'
import { getCurrentRegion, getRegion, getRegions } from '@/api/regionAPI'
import { addFavorite, getFavorites, removeFavorite } from '@/api/favoriteAPI'
import { initialSettings, isEmpty, setHasFavorites } from '@/utilities/helpers'
import { getWithExpiry, setWithExpiry } from '@/utilities/localStorageWithExpiration'
import { getAccessToken } from '@/api/http-utils'

import { uniqueString } from '@/utilities/stringFormatters'
import { COMPARE_HOMES_UPDATE_EVENT } from '@/constants/AvailabilityBlockConstants'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'

const fixRegion = region => {
  region.lat = parseFloat(region.lat)
  region.lng = parseFloat(region.lng)
  region.center = {
    lat: region.lat,
    lng: region.lng
  }
  return region
}

Vue.use(Vuex)
// VuexORM.use(VuexORMSearch, {
//   threshold: 0.3,
//   includeMatches: true,
//   shouldSort: true
// })
export default new Vuex.Store({
  // plugins: [VuexORM.install(database)],
  state: {
    inititiator: uniqueString(),
    currentLocation: {
      lat: 33.329390974215855,
      lng: -112.1973874919175,
      zoom: 9
    },
    currentRegion: null,
    regions: [],
    favorites: {},
    localFavorites: {},
    compareHomes: [],
    recents: {},
    hoveredHome: null,
    selectedHome: null
  },
  getters: {
    compareHomes: state => {
      return state.compareHomes
    },
    inCompareHomes: (state) => (home) => {
      return state.compareHomes.some(compareHome => {
        return compareHome.unit_code.toString() === home.unit_code.toString()
      })
    },
    isUserIdle: state => {
      return state.idleVue.isIdle
    },
    isUserIdleVue: state => {
      return state.idleVue
    },
    currentLocation: state => {
      return state.currentLocation
    },
    currentRegion: state => {
      return state.currentRegion
    },
    regions: state => {
      return state.regions
    },
    region: state => idOrSlug => {
      const region = state.regions.find(region => {
        return region.id === idOrSlug || region.slug === idOrSlug
      })
      return region
    },
    hoveredHome: state => {
      return state.hoveredHome
    },
    selectedHome: state => {
      return state.selectedHome
    },
    favorites: state => {
      return state.favorites
    },
    isFavorite: (state) => (home) => {
      return home.unit_code in state.favorites
    },
    getFavorite: state => home => {
      if (typeof state.favorites[home.unit_code] !== 'undefined') {
        return state.favorites[home.unit_code]
      }
      return null
    },
    localFavorites: state => {
      return state.localFavorites
    },
    isLocalFavorites: (state) => (home) => {
      return home.unit_code in state.localFavorites
    },
    getlocalFavorite: state => home => {
      if (typeof state.localFavorites[home.unit_code] !== 'undefined') {
        return state.localFavorites[home.unit_code]
      }
      return null
    },
    recents: state => {
      return state.recents
    },
    recentsSorted: state => {
      const recents = Object.values(state.recents)
      recents.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp)
      })
      return recents
    },
    recentsCount: state => {
      return Object.keys(state.recents).length
    },
    isRecent: (state) => (home) => {
      return home.unit_code in state.recents
    },
    getRecent: state => home => {
      if (typeof state.recents[home.unit_code] !== 'undefined') {
        return state.recents[home.unit_code]
      }
      return null
    }
  },
  mutations: {
    currentLocation (state, location) {
      state.curentLocation = location
    },
    currentRegion: (state, region) => {
      state.currentRegion = region
    },
    hoveredHome: (state, home) => {
      state.hoveredHome = home
    },
    selectedHome: (state, home) => {
      state.selectedHome = home
    },
    regions: (state, regions) => {
      state.regions = regions
    },
    addToCompareHomes: (state, home) => {
      console.log('Vuex.mutations.addToCompareHomes', home)
      const compareHome = {
        timestamp: new Date(),
        unit_code: home.unit_code
      }
      if (!state.compareHomes.some(compareHome => compareHome.unit_code.toString() === home.unit_code.toString())) {
        state.compareHomes.push(compareHome)

        const detail = {
          type: 'add',
          unit_code: home.unit_code,
          initiator: state.inititiator
        }
        const event = new CustomEvent(COMPARE_HOMES_UPDATE_EVENT, { detail: detail })
        window.dispatchEvent(event)
      }
    },
    removeFromCompareHomes: (state, home) => {
      console.log('Vuex.mutations.removeFromCompareHomes', home)
      const index = state.compareHomes.findIndex(compareHome => compareHome.unit_code.toString() === home.unit_code.toString())
      console.log('Vuex.mutations.removeFromCompareHomes', home, state.compareHomes, index)
      if (index > -1) {
        state.compareHomes.splice(index, 1)
        const detail = {
          type: 'remove',
          unit_code: home.unit_code,
          initiator: state.inititiator
        }
        const event = new CustomEvent(COMPARE_HOMES_UPDATE_EVENT, { detail: detail })
        window.dispatchEvent(event)
      }
    },
    localFavorites: (state, favorites) => {
      state.localFavorites = favorites
    },
    favorites: (state, favorites) => {
      state.favorites = favorites
    },
    addFavorite: (state, newFavorite) => {
      Vue.set(state.favorites, newFavorite.YardiKey, newFavorite)
    },
    removeFavorite: (state, remove) => {
      Vue.delete(state.favorites, remove.unit_code)
    },
    addLocalFavorite: (state, newFavorite) => {
      console.log('Vuex.mutations.addFavorite', newFavorite)
      Vue.set(state.localFavorites, newFavorite.unit_code, newFavorite)
      let favorites = {}
      if (localStorage.getItem('tricon_favorites') !== null) {
        favorites = JSON.parse(localStorage.getItem('tricon_favorites'))
      }
      favorites[newFavorite.unit_code] = {
        timestamp: new Date(),
        slug: newFavorite.slug,
        id: newFavorite.id,
        unit_code: newFavorite.unit_code
      }
      localStorage.setItem('tricon_favorites', JSON.stringify(favorites))
      const event = new CustomEvent('tricon-update-favorites', { detail: favorites })
      window.dispatchEvent(event)
      // setHasFavorites(true)
    },
    removeLocalFavorite: (state, remove) => {
      console.log('Vuex.mutations.removeFavorite', remove)
      Vue.delete(state.localFavorites, remove.unit_code)
      let favorites = {}
      if (localStorage.getItem('tricon_favorites') !== null) {
        favorites = JSON.parse(localStorage.getItem('tricon_favorites'))
      }
      delete favorites[remove.unit_code]
      localStorage.setItem('tricon_favorites', JSON.stringify(favorites))
      const event = new CustomEvent('tricon-update-favorites', { detail: favorites })
      window.dispatchEvent(event)
      // if (isEmpty(favorites)) {
      //   setHasFavorites(false)
      // }
    },
    recents: (state, recents) => {
      state.recents = recents
    },
    addRecent: (state, newRecent) => {
      Vue.set(state.recents, newRecent.unit_code, { timestamp: new Date() })
      let recents = {}
      if (localStorage.getItem('tricon_recents') !== null) {
        recents = JSON.parse(localStorage.getItem('tricon_recents'))
      }
      recents[newRecent.unit_code] = {
        timestamp: new Date(),
        slug: newRecent.slug,
        id: newRecent.id,
        unit_code: newRecent.unit_code
      }
      localStorage.setItem('tricon_recents', JSON.stringify(recents))
      const event = new CustomEvent('tricon-update-recents', { detail: recents })
      window.dispatchEvent(event)
    },
    removeRecent: (state, remove) => {
      Vue.delete(state.favorites, remove.unit_code)
      let recents = {}
      if (localStorage.getItem('tricon_recents') !== null) {
        recents = JSON.parse(localStorage.getItem('tricon_recents'))
      }
      delete recents[remove.unit_code]
      localStorage.setItem('tricon_recents', JSON.stringify(recents))
      const event = new CustomEvent('tricon-update-recents', { detail: recents })
      window.dispatchEvent(event)
    }
  },
  actions: {
    updateCurrentLocation ({
      commit,
      state
    }, location) {
      commit('currentLocation', location)
    },
    updateCurrentRegion ({
      commit,
      state
    }, region) {
      commit('currentRegion', region)
    },
    updateHoveredHome ({
      commit,
      state
    }, home) {
      commit('hoveredHome', home)
    },
    updateSelectedHome ({
      commit,
      state
    }, home) {
      commit('selectedHome', home)
    },
    setCurrentRegion ({
      commit,
      state
    }, region) {
      commit('currentRegion', region)
    },
    initCurrentRegion ({
      commit,
      state
    }) {
      // update regions
      const initialSetting = initialSettings()
      if (!isEmpty(initialSetting.location) && !isEmpty(initialSetting.location.region) && !isEmpty(initialSetting.location.region.id)) {
        return getRegion(initialSetting.location.region.id)
          .then(response => {
            if (response.status === 200) {
              if (typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {
                const currentRegion = response.data.data
                fixRegion(currentRegion)
                commit('currentRegion', currentRegion)
                localStorage.setItem('currentRegion', JSON.stringify(currentRegion))
                return currentRegion
              } else {
                console.error('getHeadertRegion.status === 200 but no region', response)
                throw new Error('getCurrentRegion.status !== 200')
              }
            } else {
              console.error('getHeaderRegion.status !== 200', response)
              throw new Error('getHeaderRegion.status !== 200')
            }
          }).catch(e => {
            console.error('initCurrentRegion.catch', e)
            throw e
          })
      } else {
        return getCurrentRegion({})
          .then(response => {
            if (response.status === 200) {
              if (typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {
                const currentRegion = response.data.data
                fixRegion(currentRegion)
                commit('currentRegion', currentRegion)
                localStorage.setItem('currentRegion', JSON.stringify(currentRegion))
                return currentRegion
              } else {
                console.error('getCurrentRegion.status === 200 but no region', response)
                throw new Error('getCurrentRegion.status !== 200')
              }
            } else {
              console.error('getCurrentRegion.status !== 200', response)
              throw new Error('getCurrentRegion.status !== 200')
            }
          }).catch(e => {
            console.error('initCurrentRegion.catch', e)
            throw e
          })
      }
    },
    initRegions ({
      commit,
      state
    }) {
      // load regions
      let cacheHit = false
      const url = new URL(document.location)
      console.log('Vuex.actions.initRegions url:', url, url.searchParams.has('reset'))
      if (url.searchParams.has('reset')) {
        cacheHit = false
      } else {
        try {
          const regionsJSON = getWithExpiry('tricon_regions', false)
          if (typeof regionsJSON !== 'undefined' && regionsJSON !== null) {
            const regions = JSON.parse(regionsJSON)
            commit('regions', regions)
            cacheHit = true
            return new Promise((resolve, reject) => {
              resolve(regions) // return response data to calling function
            })
          }
        } catch (e) {
          // there was no valid regions data in localStorage
        }
      }
      // update regions
      if (!cacheHit) {
        console.log('Vuex.actions.initRegions regions cache miss')
        return getRegions({})
          .then(response => {
            if (response.status === 200) {
              if (typeof response.data !== 'undefined') {
                const regions = response.data
                regions.forEach(region => {
                  fixRegion(region)
                })
                commit('regions', regions)
                setWithExpiry('tricon_regions', JSON.stringify(regions), (1 / 24), null)
                return regions
              }
            }
          }).catch(e => {
            console.error('Vuex.actions.initRegions.catch', e)
          })
      }
    },
    initRecents ({
      commit,
      state
    }) {
      const recentsJSON = localStorage.getItem('tricon_recents')
      const recents = JSON.parse(recentsJSON)
      let didRemoveOld = false
      // expire recents older than 30 days
      const recentKeys = Object.keys(recents)
      const expireBefore = subDays(new Date(), 30)
      recentKeys.forEach(key => {
        const theDate = parseISO(recents[key].timestamp)
        if (isBefore(theDate, expireBefore)) {
          delete recents[key]
          didRemoveOld = true
        }
      })
      if (didRemoveOld) {
        localStorage.setItem('tricon_recents', JSON.stringify(recents))
      }
      if (recents !== null) {
        commit('recents', recents)
      }
    },
    initLocalFavorites ({
      commit,
      state
    }) {
      const favorites = localStorage.getItem('tricon_favorites')
      if (favorites !== null) {
        commit('localFavorites', JSON.parse(favorites))
      }
      console.log('Vuex.initLocalFavorites', favorites)
    },
    initFavorites ({
      commit,
      state,
      dispatch
    }) {
      dispatch('initLocalFavorites')
      console.log('Vuex.initFavorites', getAccessToken())
      if (getAccessToken() === null || typeof getAccessToken() === 'undefined') {
        console.log('Vuex.initFavorites no accessTokens at this point', getAccessToken())
        return new Promise((resolve, reject) => {
          resolve({ favorites: {} })
        })
      }
      return getFavorites()
        .then(favorites => {
          setHasFavorites(favorites.length > 0)
          // setHasFavorites(!isEmpty(favorites))
          const remoteFavorites = {}
          favorites.forEach(favorite => {
            remoteFavorites[favorite.YardiKey] = favorite
          })
          commit('favorites', remoteFavorites)
        })
    },
    /**
     * Update the compareHomes array after a window event is posted
     * @param commit
     * @param state
     * @param event
     */
    updateCompareHomesFromEvent ({
      commit,
      state
    }, event) {
      console.log('Vuex.mutations.updateCompareHomesFromEvent', event.detail, event)
      const detail = event.detail
      if (typeof detail !== 'undefined' && 'initiator' in detail && detail.initiator !== state.inititiator) {
        if (detail.type === 'add') {
          commit('addToCompareHomes', detail)
        } else if (detail.type === 'remove') {
          commit('removeFromCompareHomes', detail)
        }
      }
    },
    setFavorites ({
      commit,
      state
    }, favorites) {
      commit('favorites', favorites)
    },
    setLocalFavorites ({
      commit,
      state
    }, favorites) {
      commit('localFavorites', favorites)
    },
    addLocalFavorite ({
      commit,
      state
    }, home) {
      console.log('Vuex.action.addLocalFavorite', home)
      return new Promise((resolve, reject) => {
        console.log('Vuex.action.addLocalFavorite in promise', home)
        commit('addLocalFavorite', home)
        resolve({ saveRemote: false })
      })
    },
    removeLocalFavorite ({
      commit,
      state
    }, home) {
      console.log('Vuex.action.removeLocalFavorite', home)
      return new Promise((resolve, reject) => {
        console.log('Vuex.action.removeLocalFavorite in promise', home)
        commit('removeLocalFavorite', home)
        resolve({ saveRemote: false })
      })
    },
    addFavorite ({
      commit,
      state
    }, home) {
      return addFavorite(home)
        .then(result => {
          const favorite = {
            YardiKey: home.unit_code
          }
          commit('addFavorite', favorite)
          setHasFavorites(true)
          return result
        })
        .catch(e => {
          if (e.status === 409) {
            // already favorited
            const favorite = {
              YardiKey: home.unit_code
            }
            commit('addFavorite', favorite)
            setHasFavorites(true)
          } else {
            throw e
          }
        })
    },
    removeFavorite ({
      commit,
      state
    }, home) {
      return removeFavorite(home)
        .then(result => {
          commit('removeFavorite', home)
          setHasFavorites(!isEmpty(state.favorites))
          return result
        })
    },
    checkUrlForFavoriteToAdd ({
      commit,
      state,
      dispatch
    }) {
      const url = new URL(window.location.href)
      console.log('saveToken', url)
      const hash = url.hash
      if (hash.includes('accessToken')) {
        const searchParams = url.searchParams
        const unitCode = searchParams.get('favorite')
        let action = 'add'
        if (typeof unitCode !== 'undefined' && unitCode !== null) {
          const home = { unit_code: unitCode }
          if (searchParams.get('action') !== null) {
            action = searchParams.get('action')
          }
          if (action === 'remove') {
            dispatch('removeFavorite', home)
          } else {
            dispatch('addFavorite', home)
          }
        }
      }
    }
  },
  modules: {}
})
