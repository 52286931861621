import axios from 'axios'
import buildUrl from 'axios/lib/helpers/buildURL'
import Cookie from 'js-cookie'
import { isEmpty } from '@/utilities/helpers'

/**
 * should we add the cacheTime parameter to api requests to work around browser and ARES
 * cache issues on data requests
 * @type {boolean}
 */
const USE_CACHE_TIME_PARAM = false

/**
 * the rounding in minutes for the cacheTime parameter added to api requests
 * @type {number}
 */
const PARAM_CACHE_MIN = (process.env.NODE_ENV !== 'production') ? 1 : 10
let v2BaseUrl = process.env.MIX_API_SERVER
v2BaseUrl = v2BaseUrl.replace('/v1/', '/v2/')
const staticV2BaseUrl = v2BaseUrl.replace('/api/v2/', '/static/')
axios.defaults.withCredentials = true
export const API = axios.create({
  baseURL: process.env.MIX_API_SERVER,
  withCredentials: false,
  crossdomain: true
  // headers: { 'X-WP-Nonce': window.noenceds, crossdomain: true }
})

export const APIv2 = axios.create({
  baseURL: v2BaseUrl,
  withCredentials: false,
  crossdomain: true,
  headers: { 'X-WP-Nonce': window.noenceds, crossdomain: true }
})

export const STATIC_API2 = axios.create({
  baseURL: staticV2BaseUrl,
  withCredentials: false,
  crossdomain: true
  // headers: { 'X-WP-Nonce': window.noenceds, crossdomain: true }
})

export const TRICON_API = axios.create({
  baseURL: process.env.MIX_TRICON_SERVER,
  withCredentials: false
})

export const API_CANCEL_TOKEN = () => {
  const CancelToken = axios.CancelToken
  return CancelToken
}

export const API_IS_CANCEL = thrown => {
  return axios.isCancel(thrown)
}

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  API.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    console.log('Starting API Request', request)
    return request
  })
  APIv2.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    console.log('Starting APIv2 Request', request)
    return request
  })
  STATIC_API2.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    console.log('Starting STATIC_API2 Request', request)
    return request
  })

  API.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    console.log('Finish API Request', response)
    return response
  })

  APIv2.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    console.log('Finish APIv2 Request', response)
    return response
  })
  STATIC_API2.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    console.log('Finish STATIC_API2 Request', response)
    return response
  })
}

// if not production log the request and result
if (process.env.NODE_ENV !== 'production') {
  TRICON_API.interceptors.request.use(request => {
    // eslint-disable-next-line no-console
    console.log('Starting Tricon API Request', request)
    return request
  })

  TRICON_API.interceptors.response.use(response => {
    // eslint-disable-next-line no-console
    console.log('Finish Tricon API Request', response)
    return response
  })
}

// add token if in local storage for TRICON_API calls
TRICON_API.interceptors.request.use(request => {
  const token = getAccessToken()
  if (token && request.url !== '/auth/refresh') {
    // only get supports standard of prefixing with `Bearer ` others
    // post and delete will fail
    request.headers.Authorization = token
  }
  return request
})

export const getFutureTimestamp = (min = PARAM_CACHE_MIN) => {
  const date = new Date()
  const timestamp = date.getTime()
  const coefficient = min * 1000 * 60
  return Math.ceil(timestamp / coefficient)
}

export const getPastTimestamp = (min = PARAM_CACHE_MIN) => {
  const date = new Date()
  const timestamp = date.getTime()
  const coefficient = min * 1000 * 60
  return Math.floor(timestamp / coefficient)
}

/**
 * adds param for trying to bust some caches by adding a timestamp (Math.floor|ceil) to n(10) minutes
 * @param params
 * @param min
 * @param usePast
 * @returns {{}}
 */
export const getStampedParams = (params = {}, min = PARAM_CACHE_MIN, usePast = true) => {
  if (USE_CACHE_TIME_PARAM) {
    if (usePast) {
      params.cache_time = getPastTimestamp(min)
    } else {
      params.cache_time = getFutureTimestamp(min)
    }
    console.log('stampedParams', params, 'min:' + min, 'usePast:' + usePast, process.env.NODE_ENV)
  }
  return params
}

export const getQueryVariable = variable => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

/**
 *
 * @param querystring
 * @return {{}}
 */
export const parseParams = (querystring) => {
  // parse query string
  console.log('parseParams', querystring, typeof querystring)
  if (isEmpty(querystring)) {
    return {}
  }
  let params = new URLSearchParams(querystring)

  // check if params is a full url
  try {
    const url = new URL(querystring)
    params = url.searchParams
  } catch (e) {
    // is just a partial
  }

  const obj = {}

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
  }

  return obj
}

export const getAccessToken = (headers = {}) => {
  return Cookie.get('accessToken')
  // const user = JSON.parse(localStorage.getItem('user'))
  // if (user && user.accessToken) {
  //   return user.accessToken
  // }
  // return null
}

export const getRefreshToken = (headers = {}) => {
  return Cookie.get('refreshToken')
  // const user = JSON.parse(localStorage.getItem('user'))
  // if (user && user.refreshToken) {
  //   return user.refreshToken
  // }
  // return null
}

export const saveTokens = () => {
  const url = new URL(window.location.href)
  const hash = url.hash
  if (hash.includes('accessToken')) {
    const splitHash = hash.split('#accessToken=')
    const tokens = splitHash[1].split('&refreshToken=')
    const authToken = tokens[0]
    const refreshToken = tokens[1]
    setRefreshToken(refreshToken)
    setToken(authToken)
  }
}

export const setToken = token => {
  Cookie.set('accessToken', token, { expires: 1 })
  // let user = localStorage.getItem('user')
  // if (user !== null) {
  //   user = JSON.parse(user)
  // } else {
  //   user = {}
  // }
  // user.accessToken = token
  // localStorage.setItem('user', JSON.stringify(user))
}

export const setRefreshToken = (token) => {
  Cookie.set('refreshToken', token, { expires: 2 })
  // let user = localStorage.getItem('user')
  // if (user !== null) {
  //   user = JSON.parse(user)
  // } else {
  //   user = {}
  // }
  // user.refreshToken = token
  // localStorage.setItem('user', JSON.stringify(user))
}

export const clearTokens = () => {
  Cookie.remove('refreshToken')
  Cookie.remove('accessToken')
}
/**
 * Build a URL by appending params to the end
 *
 * @param {string} url The base of the url (e.g., http://www.google.com)
 * @param {object} [params] The params to be appended
 * @returns {string} The formatted url
 */
export const urlBuilder = (url, params) => {
  return buildUrl(url, params)
}
