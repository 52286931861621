import { getSvgVersion } from '@/utilities/helpers'

const corePath = process.env.MIX_THEME_BASE_PATH

const assetPath = (path = '') => {
  if (path === '') {
    return corePath + 'assets/'
  } else {
    const assetPath = corePath + 'assets'
    if (path.startsWith('/')) {
      return assetPath + path
    } else {
      return assetPath + '/' + path
    }
  }
}

const iconPath = (iconName = '') => {
  const version = getSvgVersion()
  return assetPath('/icons/icons.svg?' + version + '#' + iconName)
}

const basePath = (path = '') => {
  if (path === '') {
    return corePath
  } else {
    const basePath = corePath
    if (path.startsWith('/')) {
      return basePath + path
    } else {
      return basePath + '/' + path
    }
  }
}
const fullPath = (path, params = null) => {
  const baseURL = new URL(process.env.MIX_GFORM_API)
  baseURL.pathname = path
  if (params !== null) {
    const keys = Object.keys(params)
    keys.forEach(key => {
      baseURL.searchParams.append(key, params[key])
    })
  }
  return baseURL.href
}
const urlForSize = (image, size = 'medium') => {
  // floorplan types only have size `floorplan` 1600x1600 34k
  if (typeof image.type !== 'undefined' &&
    image.type === 'floorplan') {
    size = 'floorplan'
  }
  if (typeof image.sizes !== 'undefined') {
    const sized = image.sizes[size]
    // there is a time when Ian is going to change this to be an object
    if (typeof sized === 'string') {
      return sized
    } else {
      return sized.url
    }
  } else {
    return image.url
  }
}

const widthForSize = (image, size = 'medium', defaultWidth = 600) => {
  // floorplan types only have size `floorplan` 1600x1600 34k
  if (image.type === 'floorplan') {
    size = 'floorplan'
  }
  if (typeof image.sizes !== 'undefined') {
    const sized = image.sizes[size]
    // there is a time when Ian is going to change this to be an object
    if (typeof sized === 'string') {
      return defaultWidth
    } else {
      return sized.w
    }
  } else {
    return defaultWidth
  }
}

const heightForSize = (image, size = 'medium', defaultHeight = 400) => {
  // floorplan types only have size `floorplan` 1600x1600 34k
  if (image.type === 'floorplan') {
    size = 'floorplan'
  }
  if (typeof image.sizes !== 'undefined') {
    const sized = image.sizes[size]
    // there is a time when Ian is going to change this to be an object
    if (typeof sized === 'string') {
      return defaultHeight
    } else {
      return sized.h
    }
  } else {
    return defaultHeight
  }
}

function getMailtoUrl (to, subject, body) {
  var args = []
  if (typeof subject !== 'undefined') {
    args.push('subject=' + encodeURIComponent(subject))
  }
  if (typeof body !== 'undefined') {
    args.push('body=' + encodeURIComponent(body))
  }

  var url = 'mailto:' + encodeURIComponent(to)
  if (args.length > 0) {
    url += '?' + args.join('&')
  }
  return url
}

function openPath (path, sameWindow = true) {
  let url = new URL(window.location.href)
  url = new URL(url.origin)
  url.pathname = path
  console.log(url)
  if (sameWindow) {
    window.location.href = url.href
  } else {
    window.open(url.href)
  }
}

export { assetPath, basePath, iconPath, urlForSize, heightForSize, widthForSize, getMailtoUrl, openPath, fullPath }
