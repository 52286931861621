import { API, APIv2, getStampedParams } from './http-utils'

import { GTAG_EVENT_INTERNAL_SEARCH, GTAG_PARAM_INTERNAL_SEARCH_TERM, pushGTM } from '@/api/gtag-utils'

const getRegions = (params) => {
  return API.get('/regions', { params: getStampedParams(params) })
}

const getCurrentRegion = (params) => {
  return API.get('/regions/current', { params: getStampedParams(params) })
}
const getRegion = (slug) => {
  const options = { params: getStampedParams() }
  return API.get(`/regions/${slug}`, options)
}

const getRegionCenterCity = (slug, city, state = '') => {
  const options = { params: getStampedParams() }
  let url = `/regions/${slug}/center-city/${city}`
  if (state !== '') {
    url = url + '/' + state
  }
  return APIv2.get(url, options)
}

const getRegionCenterZip = (slug, zip) => {
  const options = { params: getStampedParams() }
  return APIv2.get(`/regions/${slug}/center-zip/${zip}`, options)
}

/**
 * get list of neighborhood shapes that are available for region by id
 * @param slug the region slug
 * @return {Promise<AxiosResponse<any>>}
 */
const getRegionNeighborhoods = regionId => {
  const options = { params: getStampedParams() }
  return APIv2.get(`/regions/${regionId}/neighborhoods`, options)
}

/**
 * get the shape data from the shapeId for the region
 * @param slug the region slug
 * @param neighborhoodId the id of the neighborhood resource based on regionShapes call
 * @return {Promise<AxiosResponse<any>>}
 */
const getRegionNeighborhood = (regionId, neighborhoodId) => {
  const options = { params: getStampedParams() }
  return APIv2.get(`/regions/${regionId}/neighborhoods/${neighborhoodId}`, options)
}

const getCities = regionId => {
  const options = { params: getStampedParams() }
  return API.get(`/cities/${regionId}`, options)
}

const searchRegions = (search, deep = false, perPage = false) => {
  pushGTM(GTAG_EVENT_INTERNAL_SEARCH, { [GTAG_PARAM_INTERNAL_SEARCH_TERM]: search })
  const params = {
    text_query: search,
    deep: deep
  }
  if (perPage) {
    params.per_page = perPage
  }
  return API.get('/regions/search-deep', {
    params: getStampedParams(params)
  })
}

export {
  getRegion, getRegions, getCurrentRegion, searchRegions, getCities, getRegionCenterCity, getRegionCenterZip,
  getRegionNeighborhoods, getRegionNeighborhood
}
